import React, { useState, useEffect } from 'react';
import { NavigationBarMenuButton } from 'src/components/NavigationBarMenuButton';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View } from 'react-native';
import { Icon, Button } from '@ui-kitten/components';
import { useKeycloak } from '@react-keycloak/web';
import { ezTheme } from '../core/theme';
import WarehouseDropdownSelection from './WarehouseDropdownSelection';

// An array of screen paths that should display a dropdown selection.
// This is convenient for users because before, the account selection modal pops out everywhere ...
// ...when a warehouse account is required. For detail, please visit: https://ezom.atlassian.net/browse/EZOM-335
const SCREEN_WITH_WAREHOUSE_DROPDOWN = [
  '/a/shipments/new',
  '/a/shipments/courier-shipments',
  '/a/inventory',
  '/a/warehouse/inbound',
  '/a/warehouse/outbound',
  '/a/orders',
  'a/dispatch-order',
  '/a/warehouse/skuMappings',
  '/a/warehouse/packaging-materials',
  '/a/warehouse/pickingTasks',
  '/a/warehouse/transfer',
  '/a/store/*/order/*',
];

export default ({ isAccountManager, user }) => {
  const { keycloak } = useKeycloak();
  const navigation = useNavigation();

  const [username, setUsername] = useState('');

  useEffect(async () => {
    const profile = await keycloak.loadUserProfile();
    setUsername(`${profile?.firstName || ''} ${profile?.lastName || ''}`);
  });

  return (
    <View style={styles.container}>
      {SCREEN_WITH_WAREHOUSE_DROPDOWN.some((pattern) =>
        pattern.includes('*')
          ? new RegExp(pattern.replace(/\*/g, '.*')).test(window.location.pathname)
          : pattern === window.location.pathname,
      ) && <WarehouseDropdownSelection />}
      {isAccountManager && (
        <Button
          status="warning"
          appearance="ghost"
          onPress={() => navigation.navigate('OrgListScreen')}
          accessoryRight={(props) => <Icon {...props} name="people-outline" />}
        />
      )}
      <NavigationBarMenuButton style={styles.layout} user={user} username={username} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  layout: { alignSelf: 'center' },
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },
});
