import * as React from 'react';
import { Avatar, Card, FAB } from 'react-native-paper';
import { ScrollView, StyleSheet, Dimensions } from 'react-native';
import { observer } from 'mobx-react';
import { paperNativeTheme } from 'src/core/theme';
import Table from '../../components/Table';
import { Divider, Layout, Text } from '@ui-kitten/components';
import DevanningAppointmentModal from '../../components/DevanningAppointmentModal';
import LabelPrintModal from '../../components/LabelPrintModal';
import { useIsMobile } from 'src/core/responsive.utils';
import { UIStatusWrapper } from '../../components/ui-status';
import RowDetail from '../../components/RowDetail';
import { IC_STATUS_CODE, IC_STATUS_MAP } from '@ezom/library/lib/cjs/constants';
import { TRANSPORTATION_TYPE } from 'src/core/utils/utils';
import { inboundOrderStore, inboundOrderTableStore, warehousesStore } from '../../store';
import ConsignmentReceiveConfirmationModal from '../../components/ConsignmentReceiveConfirmationModal';
import { isNil } from 'ramda';
import ConsignmentPutawayConfirmationModal from '../../components/ConsignmentPutawayConfirmationModal';
import { useIsWmsManager } from '../../core/utils/utils';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';
import TransactionDetail from '../../components/TransactionDetail';

const commonInfos = [
  {
    label: 'Consignment No.',
    key: 'consignment_no',
  },
  {
    label: 'Status',
    key: 'status',
    format: (status) => IC_STATUS_MAP[status],
  },
  {
    label: 'Warehouse',
    key: 'to_warehouse_code',
    format: (w) => warehousesStore.getWarehouseNameEn(w),
  },
  {
    label: 'Transportation',
    key: 'transport_type',
    format: (t) => TRANSPORTATION_TYPE[t],
  },
  {
    label: 'Ref No',
    key: 'ref_no',
  },
  {
    label: 'Total volume',
    key: 'total_volume',
    format: (volume) => `${volume}㎥`,
  },
  {
    label: 'Total weight',
    key: 'total_weight',
    format: (val) => `${val}kg`,
  },
  {
    label: 'Create time',
    key: 'create_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Receive time',
    key: 'receive_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Update time',
    key: 'update_time',
    format: (number) => new Date(Number(number)).toLocaleString(),
  },
  {
    label: 'Note',
    key: 'remark',
  },
];

const windowHeight = Dimensions.get('window').height;

export default observer(({ route }) => {
  const { consignmentNumber, warehouseAccountId } = route?.params || {};

  const [detail, setDetail] = React.useState(null);

  const fetchInboundOrder = React.useCallback(async () => {
    if (warehouseAccountId !== undefined && consignmentNumber !== undefined) {
      const ic = await inboundOrderStore.getInboundOrder(warehouseAccountId, consignmentNumber);
      setDetail(ic);
    }
  });

  React.useEffect(() => {
    (async () => {
      await fetchInboundOrder();
    })();
  }, [warehouseAccountId, consignmentNumber]);

  const isMobile = useIsMobile();

  const [appointmentModalVisible, setAppointmentVisible] = React.useState(false);
  const [labelPrintModalVisible, setLabelPrintModalVisible] = React.useState(false);
  const [receivingModalVisible, setReceivingModalVisible] = React.useState(false);
  const [putawayModalVisible, setPutawayModalVisible] = React.useState(false);

  const [fabOpen, setFabOpen] = React.useState(false);

  const isWmsManager = useIsWmsManager();

  const fabActions = React.useMemo(() => {
    const actions = [
      {
        icon: 'printer',
        label: 'Print',
        onPress: () => setLabelPrintModalVisible(true),
      },
    ];
    if (detail?.status === IC_STATUS_CODE.Approved) {
      actions.push({
        icon: 'calendar',
        label: 'Book devanning',
        onPress: () => setAppointmentVisible(true),
      });
    }
    if (
      [
        IC_STATUS_CODE.Pending,
        IC_STATUS_CODE.Approved,
        IC_STATUS_CODE.Booking,
        IC_STATUS_CODE['Booking confirmed'],
      ].includes(detail?.status) &&
      isWmsManager
    ) {
      actions.push({
        icon: 'package-variant',
        label: 'Receive',
        onPress: () => setReceivingModalVisible(true),
      });
    }
    if (IC_STATUS_CODE.Received === detail?.status && isWmsManager) {
      actions.push({
        icon: 'package-variant',
        label: 'Edit Received',
        onPress: () => setReceivingModalVisible(true),
      });
      actions.push({
        icon: 'forklift',
        label: 'Putaway',
        onPress: () => setPutawayModalVisible(true),
      });
    }
    return actions;
  }, [setAppointmentVisible, detail?.status]);

  return (
    <UIStatusWrapper
      status={{
        indeterminate: !detail,
      }}>
      <ScrollView
        style={[styles.container, isMobile ? styles.mobileContainer : styles.desktopContainer]}>
        <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
          <Card.Title
            title="Common Info"
            left={(props) => <Avatar.Icon {...props} icon="information-outline" />}
          />
          <Divider />
          <Card.Content style={styles.content}>
            <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
              {commonInfos
                .slice(0, Math.ceil(commonInfos.length / 2))
                .map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
            </Layout>
            <Layout style={isMobile ? styles.mobileColumn : styles.desktopColumn}>
              {commonInfos
                .slice(Math.ceil(commonInfos.length / 2))
                .map(({ key, label, format }) => (
                  <RowDetail label={label} value={detail && detail[key]} format={format} />
                ))}
            </Layout>
          </Card.Content>
        </Card>

        <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
          <Card.Title
            title="Inbound SKU List"
            left={(props) => <Avatar.Icon {...props} icon={'table'} />}
          />
          <Divider />
          <Card.Content>
            <Table
              items={detail?.lstsku}
              displayKeys={[
                'box_no',
                'sku_code',
                'weight',
                'volume',
                'plan_qty',
                'received_qty',
                'exception_qty',
                'diff_qty',
                'putaway_qty',
              ]}
              titleByKey={{
                box_no: 'Box',
                sku_code: 'SKU',
                weight: 'Weight',
                volume: 'Volume',
                plan_qty: 'Plan Quantity',
                received_qty: 'Received Quantity',
                exception_qty: 'Exception Quantity',
                putaway_qty: 'Putaway Quantity',
                diff_qty: 'Difference',
              }}
              formatterByKey={{
                volume: volumeCmToMetre(true),
                weight: weightGramToKg(true),
                diff_qty: (_, item) =>
                  isNil(item.plan_qty) || isNil(item.received_qty) ? (
                    ''
                  ) : (
                    <Text status={item.received_qty - item.plan_qty === 0 ? 'success' : 'danger'}>
                      {item.received_qty - item.plan_qty}
                    </Text>
                  ),
              }}
            />
          </Card.Content>
        </Card>
        {detail?.transactions && detail.transactions.length > 0 ? (
          detail.transactions.map((transaction, index) => (
            <TransactionDetail key={transaction.id || index} transaction={transaction} />
          ))
        ) : (
          <TransactionDetail transaction={{}} />
        )}
        <DevanningAppointmentModal
          visible={appointmentModalVisible}
          setVisible={setAppointmentVisible}
          consignmentNumbers={consignmentNumber}
        />
        <LabelPrintModal
          warehouseAccountId={warehouseAccountId}
          visible={labelPrintModalVisible}
          setVisible={setLabelPrintModalVisible}
          ic={detail}
        />
      </ScrollView>
      <FAB.Group
        visible={true}
        isFabVisible={true}
        fabStyle={{ backgroundColor: paperNativeTheme.colors.primary }}
        open={fabOpen}
        icon={fabOpen ? 'chevron-down' : 'chevron-up'}
        actions={fabActions}
        onStateChange={({ open }) => setFabOpen(open)}
      />
      <ConsignmentReceiveConfirmationModal
        visible={receivingModalVisible}
        setVisible={setReceivingModalVisible}
        consignment={detail}
        onSubmit={async (values) => {
          await inboundOrderStore.receiveInboundOrder(
            warehouseAccountId,
            consignmentNumber,
            values.receive_time,
            values.lstsku,
          );
          await fetchInboundOrder();
          await inboundOrderTableStore.fetchItems();
        }}
      />
      <ConsignmentPutawayConfirmationModal
        visible={putawayModalVisible}
        setVisible={setPutawayModalVisible}
        consignment={detail}
        onSubmit={async (values) => {
          await inboundOrderStore.putawayInboundOrder(
            warehouseAccountId,
            consignmentNumber,
            values.lstsku,
          );
          await fetchInboundOrder();
          await inboundOrderTableStore.fetchItems();
        }}
      />
    </UIStatusWrapper>
  );
});

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginVertical: '1em',
  },
  desktopCard: {
    marginHorizontal: '0.4em',
    minWidth: '300px',
  },
  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  desktopColumn: {
    width: '50%',
    flex: 1,
    flexDirection: 'column',
  },

  mobileColumn: {
    width: '100%',
  },

  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  container: {
    overflowY: 'auto',
  },

  mobileContainer: {
    height: 0.82 * windowHeight,
  },

  desktopContainer: {
    height: 0.9 * windowHeight,
  },
});
