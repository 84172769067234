import { action, computed, flow, makeObservable, observable, runInAction } from 'mobx';
import {
  asyncEvery,
  getCourierCodeFromMapping,
  getWarehouseAccountIdFromMapping,
  getWarehouseCodeFromMapping,
  NETWORK_CALL_STATUS,
  unifiedAlert,
} from '../core/utils/utils';
import { zipObj, flatten, clone, mergeDeepRight, map, groupBy } from 'ramda';
import { gql } from '@apollo/client';
import { OUTBOUND_VALIDATION_SCHEMA } from '../components/CreateOutboundForm';
import { OC_STATUS_CODE } from '@ezom/library/lib/cjs/constants';
import { findMatchedSkuMappings } from '@ezom/library/lib/cjs/skuMapUtils';

const ORDERS = gql`
  query ordersToDispatch($storeIds: [ID!]!, $ids: [ID!]!) {
    orders(storeIds: $storeIds, ids: $ids) {
      id
      number
      email
      createdAt
      storeId
      provider
      note
      shippingAddress {
        address1
        address2
        name
        province
        provinceCode
        city
        zip
        countryCode
        company
        phone
      }
      lineItems {
        platformId
        storeId
        id
        sku
        name
        variant
        variantId
        quantity
        unitPrice
        fulfillableQuantity
      }
      fulfillments {
        id
        status
        trackingInfo {
          company
          url
          number
        }
        fulfillmentLineItems {
          id
          quantity
        }
      }
      shippingChannel
    }
  }
`;

const INVENTORY_QUERY = gql`
  query inventoriesForDispatch($warehouseAccountIds: [ID!]!, $skuCodes: [String!]!) {
    inventories(warehouseAccountIds: $warehouseAccountIds, skuCodes: $skuCodes, page_size: 500) {
      inventories {
        warehouseAccountId
        sku_id
        sku_name
        sku_code
        warehouse_code
        available_stock
        pending_stock
        onway_stock
        width
        length
        height
        weight
      }
    }
  }
`;

export const SKU_MAPPINGS = gql`
  query skuMappingForDispatch($storeSkus: [String!], $storeProductIds: [String!]) {
    skuMappings(storeSkus: $storeSkus, storeProductIds: $storeProductIds) {
      id
      qty
      storeId
      storeSku
      storeProductId
      storeProductName
      warehouseSku
      warehouseCode
      createdTime
    }
  }
`;

export class OrderDispatchStore {
  cachedOrders = null;

  cachedInventories = null;

  loadingOrders = true;

  loadingInventories = true;

  loadingSkuMappings = true;

  error = null;

  consignmentStatuses = [];

  cachedSkuMappings = null;

  onWarehouseSelectionSubmit = () => {};

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };

  getWarehouseAccountId(store, order) {
    return getWarehouseAccountIdFromMapping(store, order) ||
      // if there are multiple warehouse accounts, return null to prompt user to select
      this.warehouseAccountStore.warehouseAccountIds.length === 1
      ? this.warehouseAccountStore.warehouseAccountIds[0]
      : null || '';
  }

  get skuMappings() {
    if (this.cachedSkuMappings) {
      return this.cachedSkuMappings;
    } else {
      this.fetchSkuMappings();
      return [];
    }
  }

  getDefaultWarehouse(store, order) {
    return (
      (this.getWarehouseAccountId(store, order) &&
        (getWarehouseCodeFromMapping(store, order) ||
          this.storeStore.getStoreWarehouseCodesById(order.storeId)?.[0])) ||
      ''
    );
  }

  get outboundConsignments() {
    return this.orders.map((o, i) => {
      const store = this.storeStore.getStoreById(o.storeId);
      return {
        refNo: o.number,
        sales_no: o.number,
        note: o.shippingAddress?.phone && 'Please call receiver before delivery.',
        courier: getCourierCodeFromMapping(store, o),
        warehouseAccountId: this.getWarehouseAccountId(store, o),
        warehouse: this.getDefaultWarehouse(store, o),
        address: {
          country: o.shippingAddress.countryCode || '',
          state: o.shippingAddress.provinceCode || '',
          city: o.shippingAddress.city || '',
          post_code: o.shippingAddress.zip || '',
          street: `${o.shippingAddress.address2 || ''} ${o.shippingAddress.address1 || ''}`,
          company: o.shippingAddress.company || '',
          name: o.shippingAddress.name || '',
          phone: o.shippingAddress.phone || '',
          email: o.email || '',
        },
        skus: (this.skuQuantity[i] || [])
          .filter((s) => s.qty > 0)
          .map((s) => ({ qty: s.qty, warehouseSku: s.warehouseSku })),
      };
    });
  }

  setOutboundConsignmentSettings = (settings) => {
    this.outboundConsignmentSettings = settings;
  };

  setOutboundConsignmentSettingByIndex = (index, setting) => {
    this.outboundConsignmentSettings[index] = setting;
  };

  get mergedOutboundConsignments() {
    const mergedOcs = this.outboundConsignments.map((oc, i) =>
      mergeDeepRight(oc, this.outboundConsignmentSettings[i]),
    );
    return mergedOcs;
  }

  get validationErrors() {
    return this.mergedOutboundConsignments.map((oc) => {
      try {
        OUTBOUND_VALIDATION_SCHEMA.validateSync(oc);
        return null;
      } catch (e) {
        return e.errors.toString();
      }
    });
  }

  *createConsignments() {
    this.consignmentStatuses = this.orderIds.map(() => ({ status: NETWORK_CALL_STATUS.PENDING }));
    // Check if the given country code is correct
    const isValid = yield asyncEvery(async ({ warehouseAccountId, ...c }, i) => {
      const isCountryCodeValid = await this.countryStateCityCurrencyStore.getCountryByCode(
        c.address.country,
      );
      if (!isCountryCodeValid) {
        const country = await this.countryStateCityCurrencyStore.getCountryByName(
          c.address.country,
        );
        if (!country) {
          const message = `"${c.address.country}" is not a valid country`;
          unifiedAlert(message);
          this.consignmentStatuses[i].status = NETWORK_CALL_STATUS.FAILED;
          this.consignmentStatuses[i].error = message;
          return false;
        }
        c.address.country = country.isoCode;
      }
      return true;
    }, this.mergedOutboundConsignments);

    // if not correct, try to convert from country name to code
    if (isValid) {
      for (const [i, { warehouseAccountId, ...c }] of this.mergedOutboundConsignments.entries()) {
        try {
          const createdOutboundOrder = yield this.outboundOrderStore.addItem(warehouseAccountId, {
            ...c,
            skus: c.skus.map((s) => ({ sku: s.warehouseSku, qty: s.qty })),
          });
          runInAction(() => {
            if (createdOutboundOrder.status === OC_STATUS_CODE.Exception) {
              this.consignmentStatuses[i].status = NETWORK_CALL_STATUS.WARNING;
              this.consignmentStatuses[i].error = createdOutboundOrder.exceptionReason;
            } else {
              this.consignmentStatuses[i].status = NETWORK_CALL_STATUS.SUCCESS;
            }
          });
        } catch (err) {
          runInAction(() => {
            this.consignmentStatuses[i].status = NETWORK_CALL_STATUS.FAILED;
            this.consignmentStatuses[i].error = err?.message;
          });
        }
      }
    }
  }

  get totalWeight() {
    const inventoriesBySku = this.inventoriesBySku;
    return this.skuQuantity.map((q) => {
      let totalWeight = 0;
      q.forEach((s) => {
        if (inventoriesBySku[s.warehouseSku]) {
          totalWeight += inventoriesBySku[s.warehouseSku].weight * s.qty;
        }
      });
      return totalWeight;
    });
  }

  get totalVolume() {
    const inventoriesBySku = this.inventoriesBySku;
    return this.skuQuantity.map((q) => {
      let totalVolume = 0;
      q.forEach((s) => {
        if (inventoriesBySku[s.warehouseSku]) {
          totalVolume +=
            inventoriesBySku[s.warehouseSku].width *
            inventoriesBySku[s.warehouseSku].length *
            inventoriesBySku[s.warehouseSku].height;
        }
      });
      return totalVolume;
    });
  }

  get unmappedStoreSkus() {
    return this.orders.map((o) => {
      const unmappedStoreSkus = new Set();
      o.lineItems.forEach((item) => {
        if (
          !findMatchedSkuMappings(
            item.sku,
            o.storeId,
            item.variantId || item.platformId,
            null,
            this.skuMappings,
            true,
          )
        ) {
          unmappedStoreSkus.add(item.variant ? `${item.name} / ${item.variant}` : item.name);
        }
      });
      return [...unmappedStoreSkus];
    });
  }

  get outOfStockWarehouseSkus() {
    const stock = clone(this.stock);
    const skuQuantity = this.skuQuantity;
    const outOfStockSkus = this.orders.map(() => new Set());
    const mergedOutboundConsignments = this.mergedOutboundConsignments;

    skuQuantity.forEach((o, i) => {
      o.forEach((q) => {
        const warehouse = mergedOutboundConsignments[i]?.warehouse;
        if (stock[warehouse]) {
          if (stock[warehouse][q.warehouseSku]) {
            stock[warehouse][q.warehouseSku] -= q.qty;
          } else {
            stock[warehouse][q.warehouseSku] = -q.qty;
          }
          if (stock[warehouse][q.warehouseSku] < 0) {
            outOfStockSkus[i].add(q.warehouseSku);
          }
        } else {
          outOfStockSkus[i].add(q.warehouseSku);
        }
      });
    });
    return outOfStockSkus.map((o) => [...o]);
  }

  get skuQuantity() {
    const skuQtyByOrder = this.orders.map((o, oIndex) => {
      const store = this.storeStore.getStoreById(o.storeId);
      let skuQuantity = [];
      (o.lineItems || []).forEach((item) => {
        if (item.selected) {
          const matchedSkuMappings =
            findMatchedSkuMappings(
              item.sku,
              o.storeId,
              item.variantId || item.platformId,
              this.outboundConsignmentSettings[oIndex].warehouse ||
                this.getDefaultWarehouse(store, o),
              this.skuMappings,
              true,
            ) || [];
          skuQuantity = [
            ...skuQuantity,
            ...matchedSkuMappings.map((skuMapping) => ({
              qty: item.selectedQuantity * skuMapping.qty,
              warehouseSku: skuMapping.warehouseSku,
            })),
          ];
        }
      });
      return skuQuantity;
    });
    return skuQtyByOrder;
  }

  get orders() {
    if (this.cachedOrders) {
      return this.cachedOrders;
    } else {
      this.fetchOrders();
      return [];
    }
  }

  *fetchOrders() {
    this.loadingOrders = true;
    this.cachedOrders = null;

    this.client
      .watchQuery({
        query: ORDERS,
        variables: {
          storeIds: this.storeIds,
          ids: this.orderIds,
        },
        fetchPolicy: 'cache-and-network',
      })
      .subscribe({
        next: ({ data }) => {
          this.cachedOrders = data.orders.map((o) => ({
            ...o,
            lineItems: o.lineItems.map((item) => ({
              ...item,
              selected: item.fulfillableQuantity > 0,
              selectedQuantity: item.fulfillableQuantity,
            })),
          }));
          this.loadingOrders = false;
        },
        error: (e) => {
          this.error = e;
          console.error(e);
          this.loadingOrders = false;
        },
      });
  }

  get storeSkus() {
    return [...new Set(flatten(this.orders.map((o) => o.lineItems.map((l) => l.sku))))].filter(
      (sku) => !!sku,
    );
  }

  get storeProductIds() {
    return [
      ...new Set(
        flatten(this.orders.map((o) => o.lineItems.map((l) => l.variantId || l.platformId))),
      ),
    ].filter((id) => !!id);
  }

  get warehouseSkus() {
    return [...new Set(flatten(this.skuQuantity.map((o) => o.map((s) => s.warehouseSku))))];
  }

  *fetchInventories() {
    this.loadingInventories = true;
    try {
      const { data } = yield this.client.query({
        query: INVENTORY_QUERY,
        variables: {
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          skuCodes: this.warehouseSkus,
        },
        fetchPolicy: 'no-cache',
      });
      this.cachedInventories = data.inventories?.inventories || [];
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loadingInventories = false;
    }
  }

  get inventories() {
    if (!this.cachedInventories && this.skuMappings.length > 0) {
      this.fetchInventories();
    }
    return this.cachedInventories || [];
  }

  get inventoriesBySku() {
    return zipObj(
      this.inventories.map((inv) => inv.sku_code),
      this.inventories,
    );
  }

  get stock() {
    const stock = map(
      (groupedInventories) =>
        groupedInventories.reduce((s, inv) => {
          s[inv.sku_code] = inv.available_stock;
          return s;
        }, {}),
      groupBy((inv) => inv.warehouse_code, this.inventories),
    );
    return stock;
  }

  *fetchSkuMappings() {
    this.loadingSkuMappings = true;
    this.cachedSkuMappings = null;

    this.client
      .watchQuery({
        query: SKU_MAPPINGS,
        variables: {
          storeIds: this.storeIds,
          storeSkus: this.storeSkus,
          storeProductIds: this.storeProductIds,
        },
        fetchPolicy: 'cache-and-network',
      })
      .subscribe({
        next: ({ data }) => {
          this.cachedSkuMappings = data.skuMappings;
          this.loadingSkuMappings = false;
          this.fetchInventories();
        },
        error: (e) => {
          this.error = e;
          console.error(e);
          this.loadingSkuMappings = false;
        },
      });
  }

  constructor(
    client,
    outboundOrderStore,
    storeStore,
    orderIds,
    storeIds,
    warehouseAccountStore,
    countryStateCityCurrencyStore,
  ) {
    this.client = client;
    this.outboundOrderStore = outboundOrderStore;
    this.storeStore = storeStore;
    this.orderIds = orderIds;
    this.storeIds = storeIds;
    this.warehouseAccountStore = warehouseAccountStore;
    this.countryStateCityCurrencyStore = countryStateCityCurrencyStore;
    this.outboundConsignmentSettings = this.orderIds.map(() => ({}));
    // MobX doesn't allow markAutoObservable in subclasses
    makeObservable(
      this,
      {
        client: false,
        outboundOrderStore: false,
        storeStore: false,
        fetchOrders: flow,
        fetchSkuMappings: flow,
        fetchInventories: flow,
        outboundConsignmentSettings: observable,
        stock: computed,
        inventoriesBySku: computed,
        inventories: computed,
        warehouseSkus: computed,
        storeProductIds: computed,
        storeSkus: computed,
        orders: computed,
        skuQuantity: computed,
        outOfStockWarehouseSkus: computed,
        unmappedStoreSkus: computed,
        totalVolume: computed,
        totalWeight: computed,
        createConsignments: flow,
        validationErrors: computed,
        mergedOutboundConsignments: computed,
        setOutboundConsignmentSettingByIndex: action,
        setOutboundConsignmentSettings: action,
        outboundConsignments: computed,
        skuMappings: computed,
        cachedOrders: observable,
        cachedInventories: observable,
        loadingOrders: observable,
        loadingInventories: observable,
        loadingSkuMappings: observable,
        error: observable,
        consignmentStatuses: observable,
        cachedSkuMappings: observable,
      },
      { autoBind: true },
    );
  }
}
