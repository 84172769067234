import { gql } from '@apollo/client';
import { flow, makeAutoObservable } from 'mobx';
import { flatten } from 'ramda';
import { GET_STORES, unifiedAlert } from '../core/utils/utils';
import { parseWarehouseCourierWhitelist } from '@ezom/library/lib/cjs/store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  RECONNECT_STATUS,
  STORE_PROVIDERS,
  RECONNECT_ASYNC_STORE_KEY,
} from '@ezom/library/lib/cjs/constants';
import { Linking } from 'react-native';

const DELETE_STORE = gql`
  mutation DeleteStore($id: ID!) {
    deleteStore(id: $id)
  }
`;

const GET_ORDER_COUNTRY_CODES = gql`
  query orderCountryCodes($storeIds: [ID!]!) {
    orderCountryCodes(storeIds: $storeIds)
  }
`;

const RECONNECT_STORE = gql`
  mutation ReconnectStore($storeId: ID!, $input: ReconnectStoreInput!) {
    reconnectStore(storeId: $storeId, input: $input) {
      status
      value
    }
  }
`;

export class StoreStore {
  loading = false;
  error = null;
  cachedEnabledStores = null;
  cachedOrderCountryCodes = null;

  constructor(client) {
    this.client = client;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        client: false,
      },
      { autoBind: true },
    );
  }

  clearCache() {
    this.cachedEnabledStores = null;
    this.cachedOrderCountryCodes = null;
  }

  *getEnabledStoreIds() {
    if (!this.cachedEnabledStores) {
      yield this.fetchItems();
    }
    return (this.cachedEnabledStores || []).map((s) => s.id);
  }

  *getEnabledStores() {
    if (!this.cachedEnabledStores) {
      yield this.fetchItems();
    }
    return this.cachedEnabledStores || [];
  }

  get orderCountryCodes() {
    if (!this.cachedOrderCountryCodes && this.enabledStoreIds.length > 0) {
      this.fetchOrderCountryCodes();
    }
    return this.cachedOrderCountryCodes || [];
  }

  get enabledStores() {
    if (!this.cachedEnabledStores) {
      this.fetchItems();
    }
    return this.cachedEnabledStores || [];
  }

  get enabledStoreIds() {
    if (!this.cachedEnabledStores) {
      this.fetchItems();
    }
    return (this.cachedEnabledStores || []).map((s) => s.id);
  }

  getStoreById(id) {
    return this.enabledStores.find(({ id: storeId }) => storeId === id);
  }

  getStoreWarehouseCourierById(id) {
    const store = this.getStoreById(id);
    return parseWarehouseCourierWhitelist(store?.preference?.warehouseCourierSelection);
  }

  getStoreWarehouseCodesById(id) {
    const whitelist = this.getStoreWarehouseCourierById(id) || {};
    return flatten(Object.values(whitelist)).map((w) => w.warehouseCode);
  }

  getStoreWarehouseAccountsById(id) {
    const whitelist = this.getStoreWarehouseCourierById(id) || {};
    return flatten(Object.values(whitelist)).map((w) => w.warehouseAccountId);
  }

  *fetchItems() {
    try {
      this.loading = true;
      const { data: storesData } = yield this.client.query({ query: GET_STORES });
      this.cachedEnabledStores = storesData?.stores?.filter((s) => !s.isDisabled);
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *deleteStore(id) {
    try {
      yield this.client.mutate({
        mutation: DELETE_STORE,
        variables: {
          id,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  *fetchOrderCountryCodes() {
    try {
      const { data } = yield this.client.query({
        query: GET_ORDER_COUNTRY_CODES,
        variables: { storeIds: this.enabledStoreIds },
      });
      this.cachedOrderCountryCodes = data?.orderCountryCodes;
    } catch (error) {
      console.error(error);
    }
  }

  *reconnectStore({ store, callbackFunction, forceReconnect }) {
    try {
      this.loading = true;
      yield AsyncStorage.setItem(RECONNECT_ASYNC_STORE_KEY.storeId, store.id);
      if (store.provider === STORE_PROVIDERS.EBAY) {
        const result = yield this.client.mutate({
          mutation: RECONNECT_STORE,
          variables: {
            storeId: store.id,
            input: { forceReconnect },
          },
        });
        const res = result.data.reconnectStore;
        if (res.status === RECONNECT_STATUS.RENEW) {
          const url = new URL(res.value);

          Linking.canOpenURL(url).then((supported) => {
            if (supported) {
              Linking.openURL(url);
            } else {
              console.log("Don't know how to open URI: " + url);
            }
          });
        } else if (res.status === RECONNECT_STATUS.SUCCESS) {
          yield callbackFunction?.();
        }
      } else if (store.provider === STORE_PROVIDERS.ETSY) {
        const result = yield this.client.mutate({
          mutation: RECONNECT_STORE,
          variables: {
            storeId: store.id,
            input: {
              forceReconnect,
              codeVerifier: '',
              redirectUrl: `${window.location.protocol}//${window.location.host}/a/dashboard?refreshToken=etsy`,
            },
          },
        });
        const res = result.data.reconnectStore;
        if (res.status === RECONNECT_STATUS.RENEW) {
          const url = new URL(res.value.authUrl);
          yield AsyncStorage.setItem('codeVerifier', res.value.codeVerifier);

          Linking.canOpenURL(url).then((supported) => {
            if (supported) {
              Linking.openURL(url);
            } else {
              console.log("Don't know how to open URI: " + url);
            }
          });
        } else if (res.status === RECONNECT_STATUS.SUCCESS) {
          yield callbackFunction?.();
        }
      }
    } catch (error) {
      unifiedAlert(error.message);
    } finally {
      this.loading = false;
    }
  }
}
