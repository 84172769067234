import { DefaultTheme } from 'react-native-paper';

// Generated by https://colors.eva.design
export const uiKittenTheme = {
  'color-primary-100': '#E2F1F6',
  'color-primary-200': '#C7E2EE',
  'color-primary-300': '#9BBACD',
  'color-primary-400': '#6C879B',
  'color-primary-500': '#354859',
  'color-primary-600': '#26384C',
  'color-primary-700': '#1A2A40',
  'color-primary-800': '#101D33',
  'color-primary-900': '#0A142A',
  'color-success-100': '#E6F8D1',
  'color-success-200': '#C8F2A5',
  'color-success-300': '#99DA72',
  'color-success-400': '#6AB549',
  'color-success-500': '#34841B',
  'color-success-600': '#237113',
  'color-success-700': '#155F0D',
  'color-success-800': '#0A4C08',
  'color-success-900': '#053F07',
  'color-info-100': '#C7F3F9',
  'color-info-200': '#92E1F3',
  'color-info-300': '#59BCDD',
  'color-info-400': '#2F90BB',
  'color-info-500': '#015A8E',
  'color-info-600': '#00457A',
  'color-info-700': '#003466',
  'color-info-800': '#002452',
  'color-info-900': '#001A44',
  'color-warning-100': '#FDF6CB',
  'color-warning-200': '#FBEA98',
  'color-warning-300': '#F4D864',
  'color-warning-400': '#EAC43D',
  'color-warning-500': '#DDA704',
  'color-warning-600': '#BE8A02',
  'color-warning-700': '#9F6F02',
  'color-warning-800': '#805601',
  'color-warning-900': '#6A4500',
  'color-danger-100': '#FAE1CD',
  'color-danger-200': '#F6BD9E',
  'color-danger-300': '#E38B6A',
  'color-danger-400': '#C95C42',
  'color-danger-500': '#A52312',
  'color-danger-600': '#8D110D',
  'color-danger-700': '#76090D',
  'color-danger-800': '#5F0510',
  'color-danger-900': '#4F0312',
};

/**
 * An object that maps status strings to their corresponding color values.
 * This is used to provide consistent styling for different task statuses.
 */
export const STATUS_COLORS = {
  Completed: '#C3FBD4',
  Cancelled: '#FFCCCB',
  OnHold: '#F5F4B0',
  Initiated: '#FFF3CD',
  InstantlyCompleted: '#D4EDDA',
  Default: '#C3FBD4',
};

export const ezTheme = {
  black: '#000000',
  white: '#FFFFFF',
  darkgray: '#A9A9A9',
  backdropModalColor: 'rgba(0, 0, 0, 0.3)',
};

export const paperNativeTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: uiKittenTheme['color-primary-500'],
    secondary: uiKittenTheme['color-primary-300'],

    success: uiKittenTheme['color-success-500'],
    error: uiKittenTheme['color-danger-500'],
    warning: uiKittenTheme['color-warning-500'],
    danger: uiKittenTheme['color-danger-500'],
    info: uiKittenTheme['color-info-500'],

    enabledFeatures: uiKittenTheme['color-success-600'],

    // this affects "Badge" color
    notification: uiKittenTheme['color-info-500'],
  },
};
