import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { LIST_STORE_ALLOW_RECONNECT, STORE_PROVIDERS } from '@ezom/library/lib/cjs/constants';
import { Button, Card, Divider, Icon, Layout, Modal, Text, Toggle } from '@ui-kitten/components';
import { Linking } from 'expo';
import * as DocumentPicker from 'expo-document-picker';
import { saveAs } from 'file-saver';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { ezTheme, uiKittenTheme } from '../core/theme';
import {
  STORE,
  parseShippingRateSheet,
  shippingRatesToExcel,
  unifiedAlert,
} from '../core/utils/utils';
import { storeStore } from '../store';
import { CourierTreeSelect } from './CourierTreeSelect';
import { TextInputField } from './input/TextInputField';
import { ReconnectButton } from './ReconnectButton';
import { UIStatusWrapper } from './ui-status';
import { WarehouseCourierTreeSelect } from './WarehouseCourierTreeSelect';

const UPDATE_STORE = gql`
  mutation UpdateStore(
    $id: ID!
    $name: String!
    $preference: StorePreferenceInput!
    $enableInventorySync: Boolean
  ) {
    updateStore(
      id: $id
      input: { name: $name, preference: $preference, enableInventorySync: $enableInventorySync }
    ) {
      name
    }
  }
`;

const GET_STORE_SHIPPING_CHANNELS = gql`
  query storeShippingChannels($storeId: ID!) {
    storeShippingChannels(storeId: $storeId) {
      shippingChannel
    }
  }
`;

const ENABLE_SHOPIFY_EZOM_SHIPPING_CALCULATOR = gql`
  mutation enableEzomShippingCalculator($storeId: ID!) {
    enableEzomShippingCalculator(storeId: $storeId)
  }
`;

const DISABLE_SHOPIFY_EZOM_SHIPPING_CALCULATOR = gql`
  mutation disableEzomShippingCalculator($storeId: ID!) {
    disableEzomShippingCalculator(storeId: $storeId)
  }
`;

const GET_SHIPPING_RATES_AND_DELIVERY = gql`
  query shippingRatesAndDelivery($storeId: ID!) {
    shippingRatesAndDelivery(storeId: $storeId) {
      shippingRates {
        originCountry
        originPostcodeFrom
        originPostcodeTo
        carrierName
        zone
        weightFromKg
        weightToKg
        baseCost
        ratePerKg
        minimumCharge
      }
      deliveryZones {
        carrierName
        country
        suburb
        postcodeFrom
        postcodeTo
        zone
        minBusinessDays
        maxBusinessDays
      }
      lastMileSurcharges {
        name
        carrierName
        country
        unit
        condition
        min
        max
        fee
        additionalCharge
      }
      rasCharges {
        name
        carrierName
        suburb
        country
        state
        postcode
        charge
        suburb
      }
    }
  }
`;

const CREATE_OR_REPLACE_STORE_SHIPPING_RATES = gql`
  mutation createOrReplaceStoreShippingRates(
    $storeId: ID!
    $shippingRates: [ShippingRateInput!]!
    $deliveryZones: [DeliveryInput!]!
    $lastMileSurcharges: [LastMileSurchargeInput!]!
    $rasCharges: [RasChargeInput!]!
  ) {
    createOrReplaceStoreShippingRates(
      storeId: $storeId
      shippingRates: $shippingRates
      deliveryZones: $deliveryZones
      lastMileSurcharges: $lastMileSurcharges
      rasCharges: $rasCharges
    )
  }
`;

const onDocumentSelected = async (onDataLoaded) => {
  let result = await DocumentPicker.getDocumentAsync({});

  const reader = new FileReader();
  const XLSX = await import('xlsx/xlsx.mjs');

  reader.onload = (e) => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: 'array' });

    const sheets = workbook.SheetNames.reduce((a, sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      // By default, XLSX automatically detects and converts string to float if applicable
      // This results in imprecise representation of float numbers
      // set raw = false will leave the float numbers as string
      const json = XLSX.utils.sheet_to_json(worksheet, {
        defval: null,
        raw: false,
        rawNumbers: true,
      });
      return {
        ...a,
        [sheetName]: json,
      };
    }, {});
    onDataLoaded(sheets);
  };

  reader.readAsArrayBuffer(result.file);
};

const initShippingChannelMappings = (shippingChannels, storeShippingChannelMappings) => {
  return shippingChannels.map((c) => ({
    shippingChannel: c.shippingChannel,
    warehouseCourier: storeShippingChannelMappings.find(
      (m) => m.shippingChannel === c.shippingChannel,
    )?.warehouseCourier,
  }));
};

export const EditStoreForm = observer(({ onSuccess, store, setEditStore, refetchStores }) => {
  const client = useApolloClient();

  const [updateStore, { loading: updateStoreLoading, error: updateStoreError }] =
    useMutation(UPDATE_STORE);

  const [
    enableEzomShippingCalculator,
    { loading: enableEzomShippingCalculatorLoading, error: enableEzomShippingCalculatorError },
  ] = useMutation(ENABLE_SHOPIFY_EZOM_SHIPPING_CALCULATOR);
  const [
    disableEzomShippingCalculator,
    { loading: disableEzomShippingCalculatorLoading, error: disableEzomShippingCalculatorError },
  ] = useMutation(DISABLE_SHOPIFY_EZOM_SHIPPING_CALCULATOR);

  const {
    loading: loadingShippingChannels,
    error: shippingChannelLoadingError,
    data: shippingChannelData,
  } = useQuery(GET_STORE_SHIPPING_CHANNELS, { variables: { storeId: store.id } });

  // abbr: SR = shipping rates
  const [
    createOrReplaceShippingRates,
    { loading: createOrReplaceSRLoading, error: createOrReplaceSRError },
  ] = useMutation(CREATE_OR_REPLACE_STORE_SHIPPING_RATES);

  const shippingChannels = useMemo(
    () => shippingChannelData?.storeShippingChannels || [],
    [shippingChannelData],
  );

  const initialValues = useMemo(
    () => ({
      name: store.name,
      shippingChannelMappings: initShippingChannelMappings(
        shippingChannels,
        store?.preference?.shippingChannelMappings || [],
      ),
      warehouseCourierSelection: store?.preference?.warehouseCourierSelection || [],
      enableInventorySync: store?.enableInventorySync || false,
    }),
    [store.name, shippingChannels, store?.preference],
  );

  const onUploadRateButtonPressed = () => {
    onDocumentSelected((sheets) => {
      const { shippingRates, deliveryZones, lastMileSurcharges, rasCharges, errors } =
        parseShippingRateSheet(sheets);

      if (errors.length > 0) {
        unifiedAlert(errors.join('\n'));
        return;
      }
      createOrReplaceShippingRates({
        variables: {
          storeId: store.id,
          shippingRates,
          deliveryZones,
          lastMileSurcharges,
          rasCharges,
        },
      })
        .then(() => {
          unifiedAlert('Successfully uploaded');
        })
        .catch((error) => unifiedAlert('Failed! ' + JSON.stringify(error.message || error)));
    });
  };

  const onDownloadSRButtonPressed = async () => {
    const {
      data: { shippingRatesAndDelivery },
    } = await client.query({
      query: GET_SHIPPING_RATES_AND_DELIVERY,
      variables: { storeId: store.id },
    });

    const wbout = await shippingRatesToExcel(
      shippingRatesAndDelivery.shippingRates,
      shippingRatesAndDelivery.deliveryZones,
      shippingRatesAndDelivery.lastMileSurcharges,
      shippingRatesAndDelivery.rasCharges,
    );

    const blobFile = new Blob([new Uint8Array(wbout)], { type: 'application/octet-stream' });
    saveAs(blobFile, `ShippingRates_${store.name}.xlsx`);
  };

  const updateEditStore = async () => {
    const { data } = await client.query({
      query: STORE,
      variables: {
        id: store.id,
      },
      fetchPolicy: 'no-cache',
    });
    setEditStore(data?.store);
    refetchStores();
  };

  const onIntegrationButtonPressed = async () => {
    try {
      await enableEzomShippingCalculator({
        variables: {
          storeId: store.id,
        },
      });
      unifiedAlert('Ezom Shipping Calculator successfully enabled!');
      await updateEditStore();
    } catch (e) {
      unifiedAlert('Failed to enable Ezom Shipping Calculator. ' + JSON.stringify(e.message || e));
    }
  };

  const onDisableEzomShippingCalculatorButtonPressed = async () => {
    try {
      await disableEzomShippingCalculator({
        variables: {
          storeId: store.id,
        },
      });
      unifiedAlert('Ezom Shipping Calculator has been disabled.');
      await updateEditStore();
    } catch (e) {
      unifiedAlert(
        'Failed to disable Ezom Shipping Calculator. This could be because of network error, or because the shop currently has the other custom calculator enabled. ' +
          JSON.stringify(e.message || e),
      );
    }
  };

  const [couriers, setCouriers] = useState([]);
  const [ezomShippingCalculatorInstructionModal, setEzomShippingCalculatorInstructionModal] =
    useState(false);

  const checkShowReconnect = ({ data }) => {
    const check = LIST_STORE_ALLOW_RECONNECT.includes(data.provider);
    return check && (data.isReconnected === true || data.connectionError);
  };

  return (
    <UIStatusWrapper
      status={{
        error: updateStoreError || shippingChannelLoadingError,
        emtpy: shippingChannels.length === 0,
        indeterminate: updateStoreLoading || loadingShippingChannels,
      }}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        validationSchema={Yup.object({
          name: Yup.string().required(),
          warehouseCourierSelection: Yup.array().of(Yup.string()).required(),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          updateStore({
            variables: {
              id: store.id,
              name: values.name,
              preference: {
                shippingChannelMappings: values.shippingChannelMappings.filter(
                  (m) => !!m.warehouseCourier,
                ),
                warehouseCourierSelection: values.warehouseCourierSelection,
              },
              enableInventorySync: values.enableInventorySync,
            },
          }).then(onSuccess);
        }}>
        {(props) => {
          const Footer = (footerProps) => (
            <View {...footerProps}>
              <Text status="info">
                This feature depends on{' '}
                <strong>Shopify’s Third-party Shipping Rate Calculator</strong> functionality, which
                will only be available in the Advanced or Plus Shopify plan. Contact{' '}
                <a
                  target="_blank"
                  href="https://help.shopify.com/en/manual/shipping/setting-up-and-managing-your-shipping/enabling-shipping-carriers">
                  Shopify
                </a>{' '}
                if you are unsure.
              </Text>
              <Button
                loading={props.isSubmitting}
                disabled={updateStoreLoading || !props.isValid}
                style={styles.footerControl}
                size="small"
                onPress={() => props.handleSubmit()}>
                Save
              </Button>
            </View>
          );

          return (
            <Card
              style={styles.card}
              header={(props) => (
                <View {...props}>
                  <Text category="h6">{'Edit store'}</Text>
                </View>
              )}
              footer={Footer}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                {...props}>
                <Text category="h6">Store details</Text>
                {checkShowReconnect({ data: store }) && (
                  <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <ReconnectButton
                      showInfo
                      reconnectHandle={async () => {
                        await storeStore.reconnectStore({
                          store,
                          forceReconnect: true,
                        });
                      }}
                    />
                  </View>
                )}
              </View>
              <TextInputField
                label="Name"
                name="name"
                style={styles.field}
                placeholder="Store name"
                {...props}
              />
              <Divider style={{ marginVertical: 10 }} />

              <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category="h6">Warehouses and Couriers</Text>
                {store.provider === STORE_PROVIDERS.SHOPIFY && (
                  <Toggle
                    checked={props.values.enableInventorySync}
                    onChange={(val) => props.setFieldValue('enableInventorySync', val)}>
                    Auto inventory sync
                  </Toggle>
                )}
              </Layout>
              <WarehouseCourierTreeSelect
                value={props.values.warehouseCourierSelection}
                setValue={(val) => props.setFieldValue('warehouseCourierSelection', val)}
              />

              <Divider style={{ marginVertical: 10 }} />
              {props.values.shippingChannelMappings.length > 0 ? (
                <Card
                  style={styles.card}
                  header={(props) => (
                    <View {...props}>
                      <Text category="h6">Shipping Channel Mapping</Text>
                    </View>
                  )}>
                  {props.values.shippingChannelMappings.map((channel, i) => {
                    const name = `shippingChannelMappings[${i}]`;
                    return (
                      <CourierTreeSelect
                        style={styles.field}
                        error={props.errors.shippingChannelMappings?.[i]}
                        tourched={props.touched.shippingChannelMappings?.[i]}
                        label={channel.shippingChannel}
                        value={channel.warehouseCourier}
                        setValue={(val) => {
                          props.setFieldValue(name, {
                            ...channel,
                            warehouseCourier: val.value,
                          });
                        }}
                        whiteListValues={props.values.warehouseCourierSelection}
                        {...props}
                      />
                    );
                  })}
                </Card>
              ) : null}
              {store.provider === STORE_PROVIDERS.SHOPIFY && (
                <>
                  <Divider style={{ marginVertical: 10 }} />
                  <View {...props}>
                    <Text category="h6">EZOM Shipping Calculator</Text>
                  </View>
                  {!store.shopifyEzomCarrierId ? (
                    <View style={styles.evenColumns}>
                      <Text>
                        EZOM Shipping Rate Calculator is not integrated with the your store.
                      </Text>
                      <Button status={'success'} onPress={onIntegrationButtonPressed}>
                        Integrate now
                      </Button>
                    </View>
                  ) : (
                    <View>
                      <View style={styles.evenColumns}>
                        <Card style={{ width: '100%', marginVertical: 8 }}>
                          <Text>
                            EZOM Shipping Calculator is currently integrated with your store.
                          </Text>
                          <Button
                            appearance={'outline'}
                            status={'info'}
                            onPress={() => setEzomShippingCalculatorInstructionModal(true)}>
                            How to add EZOM Shipping Calculator to your shop?
                          </Button>
                        </Card>
                      </View>
                      <Card style={styles.card}>
                        <View>
                          <Text category="s1">Add new shipping rates</Text>
                          <View style={{ ...styles.evenColumns, width: '100%' }}>
                            <Button
                              appearance="ghost"
                              onPress={() =>
                                Linking.openURL(
                                  require('../spreadsheetTemplates/ShippingRateTemplate.xlsx'),
                                )
                              }
                              accessoryLeft={(props) => <Icon {...props} name="layout-outline" />}>
                              Download Template
                            </Button>
                            <Button
                              appearance="outline"
                              disabled={
                                createOrReplaceSRLoading || disableEzomShippingCalculatorLoading
                              }
                              accessoryLeft={(props) => <Icon {...props} name="upload-outline" />}
                              onPress={onUploadRateButtonPressed}>
                              Upload rates
                            </Button>
                          </View>
                          <Text
                            style={{
                              backgroundColor: uiKittenTheme['color-warning-100'],
                              padding: '1em',
                              marginVertical: 8,
                            }}>
                            If you upload new rates, the existing rates will be lost.
                          </Text>
                        </View>
                      </Card>

                      <Card style={styles.card}>
                        <Text category="s1">View rates</Text>
                        <Button
                          accessoryLeft={(props) => <Icon {...props} name="download-outline" />}
                          appearance="outline"
                          disabled={
                            createOrReplaceSRLoading || disableEzomShippingCalculatorLoading
                          }
                          onPress={onDownloadSRButtonPressed}>
                          Download Shipping Rate
                        </Button>
                      </Card>

                      <Card style={styles.card}>
                        <Text category="s1">Disable EZOM Shipping Calculator</Text>
                        <Button
                          appearance="outline"
                          status="warning"
                          disabled={
                            disableEzomShippingCalculatorLoading || createOrReplaceSRLoading
                          }
                          onPress={onDisableEzomShippingCalculatorButtonPressed}>
                          Disable
                        </Button>
                      </Card>
                    </View>
                  )}
                </>
              )}
            </Card>
          );
        }}
      </Formik>
      <EzomShippingCaculatorModal
        visible={ezomShippingCalculatorInstructionModal}
        closeModal={() => setEzomShippingCalculatorInstructionModal(false)}
      />
    </UIStatusWrapper>
  );
});

const BoldText = (props) => <Text style={styles.boldText}>{props.children}</Text>;

const ItalicText = (props) => <Text style={styles.italicText}>{props.children}</Text>;

const EzomShippingCaculatorModal = ({ visible, closeModal }) => (
  <Modal visible={visible} backdropStyle={styles.backdrop} onBackdropPress={closeModal}>
    <Card
      style={styles.card}
      header={(props) => (
        <Text {...props} category={'h6'}>
          How to apply EZOM Shipping Calculator into my Shipping Profile?
        </Text>
      )}
      footer={(props) => (
        <Button {...props} appearance={'ghost'} status="info" onPress={closeModal}>
          Done
        </Button>
      )}>
      <View style={{ backgroundColor: uiKittenTheme['color-primary-100'] }}>
        <Text style={styles.step}>
          <BoldText>Step 1.</BoldText> Go to{' '}
          <ItalicText>Settings {'>'} Shipping and delivery</ItalicText>.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 2.</BoldText> Under the <ItalicText>Shipping</ItalicText> section, select
          the shipping profile that you want to apply the EZOM Shipping Calculator.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 3.</BoldText> Under the <ItalicText>Shipping to</ItalicText> section, Press
          the <ItalicText>Add rate</ItalicText> button.
        </Text>
        <Text>
          <ItalicText>Note:</ItalicText> If no shipping rate is available, you might need to create
          a shipping zone before proceeding to step 4.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 4.</BoldText> In the dialogue that just appears, select{' '}
          <ItalicText>Use carrier or app to calculate rates</ItalicText>.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 5.</BoldText> Confirm that the rate service is{' '}
          <BoldText>EZOM Shipping Calculator (Rates provided by app)</BoldText>.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 6.</BoldText> Press <ItalicText>Done</ItalicText> to save the carrier
          service selection.
        </Text>
        <Text style={styles.step}>
          <BoldText>Step 7.</BoldText> Press <ItalicText>Save</ItalicText> to apply the new change
          in the Shipping Channel.
        </Text>
      </View>
    </Card>
  </Modal>
);

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    marginTop: '0.5em',
  },
  card: {
    flex: 1,
    margin: 2,
    maxHeight: '95vh',
    overflow: 'auto',
  },

  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },

  boldText: {
    fontWeight: 'bold',
  },

  italicText: {
    fontStyle: 'italic',
  },

  step: {
    marginVertical: 10,
  },

  evenColumns: { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' },
});
