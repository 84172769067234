import React, { memo, useEffect } from 'react';
import { Dimensions, ScrollView, StyleSheet, View, Linking } from 'react-native';
import { Card, IconButton, Avatar, RadioButton } from 'react-native-paper';
import Background from '../components/Background';
import { gql, useMutation, useQuery } from '@apollo/client';
import { UIStatus, UIStatusWrapper } from '../components/ui-status';
import { useRoute } from '@react-navigation/core';
import { differenceInDays } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Icon,
  Layout,
  Text,
  Button as UiKittenButton,
  Modal as UIKittenModal,
  Text as UIKittenText,
} from '@ui-kitten/components';
import { AddStoreForm } from '../components/AddStoreForm';
import { EditStoreForm } from '../components/EditStoreForm';
import { ezTheme, paperNativeTheme } from '../core/theme';
import {
  STORES,
  useIsEzomUser,
  useIsWmsManager,
  unifiedAlert,
  useOrgId,
} from '../core/utils/utils';
import { observer } from 'mobx-react';
import { inventoryStore, warehouseAccountStore, storeStore, warehousesStore } from '../store';
import BalanceInfo, { STATUS_BY_BALANCE } from './user-account/BalanceInfo';
import { useIsMobile } from '../core/responsive.utils';
import { AddWmsForm } from '../components/AddWmsForm';
import {
  RECONNECT_STATUS,
  STORE_PROVIDERS,
  WAREHOUSE_PROVIDERS,
  RECONNECT_TYPE,
  RECONNECT_ASYNC_STORE_KEY,
  LIST_STORE_ALLOW_RECONNECT,
  LIST_WAREHOUSE_ALLOW_RECONNECT,
} from '@ezom/library/lib/cjs/constants';
import { DeleteConfirmationModal } from '../components/DeleteConfirmationModal';
import { get4pxLink } from '../utils/oAuth-util';
import { ReconnectButton } from '../components/ReconnectButton';

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: ezTheme.backdropModalColor,
  },

  card: {
    marginBottom: '1em',
    border: '1px solid #F2F2F2',
  },

  cardActions: {
    flexFlow: 'row wrap',
  },

  cardActionButtons: {
    marginTop: '0.2em',
    flexBasis: '100%',
  },

  footer: {
    textAlign: 'center',
    marginBottom: '1em',
    color: 'rgba(0, 0, 0, 0.54)',
  },

  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '0.2em',
  },

  mobileTitleContainer: {
    flexDirection: 'column',
  },

  innerTitleContainer: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  mobileInnerTitleContainer: {
    width: '100%%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  titleCard: {
    flex: 1,
    margin: 2,
  },

  storeActions: {
    flexDirection: 'row',
    display: 'flex',
  },

  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  warningMessage: {
    margin: 1,
  },

  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20, maxWidth: 400 },

  modalStyle: { backgroundColor: 'white', marginHorizontal: '40%', width: 600, padding: 20 },
});

const DISABLE_STORE = gql`
  mutation UpdateStore($id: ID!, $isDisabled: Boolean!) {
    updateStore(id: $id, input: { isDisabled: $isDisabled }) {
      isDisabled
    }
  }
`;

const UPDATE_TOKEN_STORE = gql`
  mutation UpdateTokenStore($storeId: ID!, $input: UpdateTokenStoreInput) {
    updateTokenStore(storeId: $storeId, input: $input) {
      id
    }
  }
`;

const UPDATE_TOKEN_WAREHOUSE_4PX = gql`
  mutation UpdateTokenWarehouse4PX($warehouseAccountId: ID!, $input: UpdateTokenWarehouseInput!) {
    updateTokenWarehouse4PX(warehouseAccountId: $warehouseAccountId, input: $input) {
      id
    }
  }
`;

const windowHeight = Dimensions.get('window').height;

const DashboardScreen = observer(({ navigation }) => {
  const [addStoreVisible, setAddStoreVisible] = React.useState(false);
  const [addWmsVisible, setAddWmsVisible] = React.useState(false);
  const [editStore, setEditStore] = React.useState();
  const [deleteStore, setDeleteStore] = React.useState();
  const [deleteWarehouseAccount, setDeleteWarehouseAccount] = React.useState();
  const isMobile = useIsMobile();

  const [updateTokenStore] = useMutation(UPDATE_TOKEN_STORE);
  const [updateTokenWarehouse4PX] = useMutation(UPDATE_TOKEN_WAREHOUSE_4PX);

  useEffect(() => {
    warehouseAccountStore.fetchItems();
    refetchStores();
  }, []);

  const checkShowReconnect = ({ type, data }) => {
    const check =
      type === RECONNECT_TYPE?.store
        ? LIST_STORE_ALLOW_RECONNECT?.includes(data.provider)
        : LIST_WAREHOUSE_ALLOW_RECONNECT?.includes(data.provider);
    return check && data.connectionError;
  };

  const renderReconnect = ({ type, data }) => {
    return (
      <>
        {checkShowReconnect({ type, data }) && (
          <Layout
            style={{
              display: 'flex',
              padding: 10,
              flex: 1,
              flexDirection: 'column',
            }}>
            <Text
              style={{
                color: 'red',
                paddingBottom: 5,
              }}>{`An error occurred while auto-refreshing the token: "${data.connectionError}"`}</Text>
            <Layout
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <Text
                style={{
                  paddingRight: 10,
                  color: 'red',
                }}>{`Please click Re-connect button to update authorization`}</Text>
            </Layout>
          </Layout>
        )}
      </>
    );
  };

  const reloadDashboardPage = () => {
    const url = '/a/dashboard';
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
        console.log("Don't know how to open URI: " + url);
      }
    });
  };

  const route = useRoute();
  useEffect(async () => {
    if (route.params && route.params.code) {
      if (route.params.addWarehouse) {
        let warehouseAccount;
        try {
          warehouseAccount = await warehouseAccountStore.addWmsAccount(
            '4PX Australia',
            WAREHOUSE_PROVIDERS.FOURPX,
            route.params.code,
            window.location.href.split('?')[0],
          );
        } catch (error) {
          unifiedAlert('Unable to add warehouse. Please try again later.');
        }

        if (warehouseAccount) {
          try {
            // Sync up SKU at first connection
            await inventoryStore.syncSkus(warehouseAccount.id);
          } catch (error) {
            unifiedAlert('Unable to synchronise inventories. Please try again later.');
          }
        }
      } else if (route.params.updateWarehouse) {
        if (route.params?.provider?.toLowerCase() === WAREHOUSE_PROVIDERS.FOURPX.toLowerCase()) {
          const warehouseId = await AsyncStorage.getItem(RECONNECT_ASYNC_STORE_KEY.warehouse4PX);
          try {
            const res = await updateTokenWarehouse4PX({
              variables: {
                warehouseAccountId: warehouseId,
                input: {
                  authorisationCode: route.params.code,
                },
              },
            });
          } catch (error) {
            unifiedAlert(error.message);
          }
          reloadDashboardPage();
        }
      } else if (route.params.refreshToken) {
        const storeId = await AsyncStorage.getItem(RECONNECT_ASYNC_STORE_KEY.storeId);
        if (`${route.params.refreshToken}`.toLowerCase() === STORE_PROVIDERS.ETSY.toLowerCase()) {
          const code = route.params.code;
          const codeVerifier = await AsyncStorage.getItem('codeVerifier');
          const redirectUrl = `${window.location.protocol}//${window.location.host}/a/dashboard?refreshToken=etsy&storeId=${storeId}`;
          try {
            await updateTokenStore({
              variables: {
                storeId,
                input: {
                  code,
                  codeVerifier,
                  redirectUrl,
                },
              },
            });
          } catch (e) {
            unifiedAlert(e);
          }
        } else if (
          `${route.params.refreshToken}`.toLowerCase() === STORE_PROVIDERS.EBAY.toLowerCase()
        ) {
          const code = route.params.code;
          try {
            await updateTokenStore({
              variables: {
                storeId,
                input: {
                  code,
                },
              },
            });
          } catch (e) {
            unifiedAlert(e);
          }
        }
        reloadDashboardPage();
      }
    }
  }, [route.params]);

  const isEzomUser = useIsEzomUser();
  const { loading, error, data, refetch: refetchStores } = useQuery(STORES, { skip: !isEzomUser });
  const storeUIStatus = new UIStatus(
    loading,
    error,
    !data || !data.stores || data.stores.length === 0,
  );

  const warehouseUIStatus = new UIStatus(
    warehouseAccountStore.loading,
    warehouseAccountStore.error,
    !warehouseAccountStore.warehouseAccounts ||
      warehouseAccountStore.warehouseAccounts.length === 0,
  );

  const [disableStore, { loading: disableStoreLoading, error: disableStoreError }] =
    useMutation(DISABLE_STORE);

  const isWmsManager = useIsWmsManager();

  const orgId = useOrgId();

  return (
    <Background fullWidth={true}>
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={{
          maxHeight: windowHeight - 130,
          overflowY: 'auto',
        }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigation.navigate('UserAccountBalanceScreen')}>
          <BalanceInfo onlyShowIfBelow={STATUS_BY_BALANCE.WARNING} />
        </div>
        {isEzomUser ? (
          <Card style={styles.card}>
            <Card.Title title="Stores" left={(props) => <Avatar.Icon {...props} icon="dolly" />} />
            <Card.Content style={styles.cardsContainer}>
              <UIStatusWrapper status={storeUIStatus} emptyDataMsg={'No stores found'}>
                {data &&
                  data?.stores &&
                  data.stores
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((store, index) => {
                      return (
                        <UIStatusWrapper
                          status={new UIStatus(disableStoreLoading, disableStoreError)}
                          key={index}>
                          <Card style={styles.card} key={store.id}>
                            <Card.Title
                              title={store.name}
                              subtitle={`${store.provider} STORE ID: ${store.id}`}
                              left={(props) => (
                                <RadioButton
                                  {...props}
                                  status={store.isDisabled ? 'unchecked' : 'checked'}
                                  onPress={() => {
                                    disableStore({
                                      variables: {
                                        id: store.id,
                                        isDisabled: !store.isDisabled,
                                      },
                                    }).then(refetchStores);
                                  }}
                                />
                              )}
                              right={(props) => {
                                return (
                                  <View style={styles.storeActions}>
                                    {checkShowReconnect({
                                      type: RECONNECT_TYPE?.store,
                                      data: store,
                                    }) && (
                                      <ReconnectButton
                                        reconnectHandle={async () => {
                                          await storeStore.reconnectStore({
                                            store,
                                            callbackFunction: async () => {
                                              await refetchStores();
                                            },
                                          });
                                        }}></ReconnectButton>
                                    )}

                                    <IconButton
                                      {...props}
                                      icon="pencil"
                                      onPress={() => {
                                        setEditStore(store);
                                      }}
                                    />
                                    <IconButton
                                      {...props}
                                      icon="delete"
                                      disabled={disableStoreLoading}
                                      color={paperNativeTheme.colors.danger}
                                      onPress={() => {
                                        setDeleteStore(store);
                                      }}
                                    />
                                  </View>
                                );
                              }}
                            />
                            {renderReconnect({
                              data: store,
                              type: RECONNECT_TYPE?.store,
                            })}
                          </Card>
                        </UIStatusWrapper>
                      );
                    })}
              </UIStatusWrapper>

              <Card style={styles.card} key="add-store">
                <UiKittenButton
                  appearance="ghost"
                  accessoryLeft={(props) => <Icon {...props} name="plus-circle-outline" />}
                  onPress={() => setAddStoreVisible(true)}>
                  Add store
                </UiKittenButton>

                <UIKittenModal
                  visible={addStoreVisible}
                  backdropStyle={styles.backdrop}
                  onBackdropPress={() => setAddStoreVisible(false)}>
                  <AddStoreForm
                    navigation={navigation}
                    onSubmit={() => setAddStoreVisible(false)}
                  />
                </UIKittenModal>

                <UIKittenModal
                  visible={editStore !== undefined}
                  backdropStyle={styles.backdrop}
                  style={{ minWidth: 375, width: isMobile ? '90%' : '50%' }}
                  onBackdropPress={() => setEditStore(undefined)}>
                  {editStore && (
                    <EditStoreForm
                      store={editStore}
                      name={editStore}
                      onSuccess={() => {
                        setEditStore(undefined);
                        refetchStores();
                      }}
                      setEditStore={setEditStore}
                      refetchStores={refetchStores}
                    />
                  )}
                </UIKittenModal>

                <DeleteConfirmationModal
                  visible={deleteStore}
                  title={`Delete store: ${deleteStore?.name}`}
                  warningMessage="this will erase your orders data related to this store"
                  onCancel={() => setDeleteStore(undefined)}
                  onSubmit={async () => {
                    await storeStore.deleteStore(deleteStore.id);
                    setDeleteStore(undefined);
                    refetchStores();
                  }}
                />
              </Card>
            </Card.Content>

            <Card.Actions style={styles.cardActions}>
              <UiKittenButton
                style={styles.cardActionButtons}
                appearance="outline"
                onPress={() => navigation.navigate('FulfillTable')}
                accessoryLeft={(props) => <Icon {...props} animation="shake" name="car" />}>
                Fulfill orders
              </UiKittenButton>

              <UiKittenButton
                style={styles.cardActionButtons}
                onPress={() => navigation.navigate('OrderListScreen')}
                disabled={storeUIStatus.indeterminate || storeUIStatus.empty}
                accessoryLeft={(props) => <Icon {...props} name="list-outline" />}>
                View orders
              </UiKittenButton>
            </Card.Actions>
          </Card>
        ) : null}
        <Card style={{ ...styles.card, marginBottom: 0 }}>
          <Card.Title
            title="Warehouse Management Systems"
            left={(props) => <Avatar.Icon {...props} icon="warehouse" />}
          />

          <Card.Content style={styles.cardsContainer}>
            <UIStatusWrapper
              status={warehouseUIStatus}
              loadingDataMsg={
                warehouseAccountStore.loading ? 'Updating warehouse account' : 'Loading'
              }
              emptyDataMsg={<div>No warehouses found</div>}>
              {warehouseAccountStore?.warehouseAccounts
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((d, index) => {
                  if (!d) return null;
                  const diffDays = d.expiredTime
                    ? differenceInDays(new Date(d.expiredTime), new Date())
                    : Number.MAX_VALUE;
                  return (
                    <Card style={styles.card} key={index}>
                      {(d.isMasterAccount &&
                        (d.masterAccountAdminOrganisationId !== orgId || !isWmsManager)) ||
                      (!isEzomUser && !isWmsManager) ? (
                        <Card.Title title={'Warehouse connected'} subtitle={d.provider} />
                      ) : (
                        <Card.Title
                          title={d.name}
                          subtitle={`${d.provider} ${d.accountNumber || ''} ID: ${d.id}`}
                          right={(props) => {
                            return (
                              <View style={styles.storeActions}>
                                {checkShowReconnect({
                                  type: RECONNECT_TYPE?.warehouse,
                                  data: d,
                                }) && (
                                  <ReconnectButton
                                    reconnectHandle={async () => {
                                      await warehouseAccountStore.reconnectWarehouse4PX({
                                        warehouseAccount: d,
                                      });
                                    }}></ReconnectButton>
                                )}
                                <IconButton
                                  {...props}
                                  icon="pencil"
                                  onPress={() =>
                                    navigation.navigate('EditWmsAccountScreen', { id: d.id })
                                  }
                                />
                                <IconButton
                                  {...props}
                                  icon="delete"
                                  disabled={disableStoreLoading}
                                  color={paperNativeTheme.colors.danger}
                                  onPress={() => {
                                    setDeleteWarehouseAccount(d);
                                  }}
                                />
                              </View>
                            );
                          }}
                        />
                      )}

                      {diffDays < 7 && (
                        <Card.Content>
                          <UIKittenText status="warning" style={styles.warningMessage}>
                            {`Connection ${
                              diffDays > 0 ? `will expire in ${diffDays} days` : `expired`
                            }. Please update it now.`}
                          </UIKittenText>
                        </Card.Content>
                      )}
                      {renderReconnect({
                        data: d,
                        type: RECONNECT_TYPE?.warehouse,
                      })}
                    </Card>
                  );
                })}
            </UIStatusWrapper>
            <Card style={styles.card} key="add-store">
              <UiKittenButton
                appearance="ghost"
                accessoryLeft={(props) => <Icon {...props} name="plus-circle-outline" />}
                onPress={() => setAddWmsVisible(true)}>
                Add WMS
              </UiKittenButton>

              <UIKittenModal
                visible={addWmsVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setAddWmsVisible(false)}>
                <AddWmsForm
                  navigation={navigation}
                  onSubmit={() => {
                    warehouseAccountStore.fetchItems();
                    setAddWmsVisible(false);
                  }}
                />
              </UIKittenModal>

              <DeleteConfirmationModal
                visible={deleteWarehouseAccount}
                title={`Delete warehouse account: ${deleteWarehouseAccount?.name}`}
                warningMessage="this will erase this will erase all your inventory and SKU information related to this account"
                onCancel={() => setDeleteWarehouseAccount(undefined)}
                onSubmit={async () => {
                  await warehouseAccountStore.deleteWmsAccount(deleteWarehouseAccount.id);
                  setDeleteWarehouseAccount(undefined);
                }}
              />
            </Card>
          </Card.Content>

          <Card.Actions style={styles.cardActions}>
            {/*FIXME: Go to inventory dashboard (https://github.com/ezspace/EzPlatform/issues/73)*/}
            <UiKittenButton
              style={styles.cardActionButtons}
              onPress={() => navigation.navigate('Inventory')}
              disabled={warehouseUIStatus.indeterminate || warehouseUIStatus.empty}
              accessoryLeft={(props) => <Icon {...props} name="list-outline" />}>
              View inventories
            </UiKittenButton>
          </Card.Actions>
        </Card>
      </ScrollView>
    </Background>
  );
});

export default memo(DashboardScreen);
