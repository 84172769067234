import React, { useLayoutEffect, useMemo, useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import Background from 'src/components/Background';
import { UIStatusWrapper } from '../../components/ui-status';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { Button, Card, Icon, Layout, ModalService, Text } from '@ui-kitten/components';
import { TextInputField } from '../../components/input/TextInputField';
import * as Yup from 'yup';
import { printerSettingStore, warehouseAccountStore, warehousesStore } from '../../store';
import InlineTable from '../../components/InlineTable';
import {
  LIST_WAREHOUSE_ALLOW_RECONNECT,
  WAREHOUSE_PROVIDERS,
} from '@ezom/library/lib/cjs/constants';
import WarehouseLocationInput from '../../components/WarehouseLocationInput';
import { DeleteConfirmationModal } from '../../components/DeleteConfirmationModal';
import { unifiedAlert, unifiedConfirm } from '../../core/utils/utils';
import { ezTheme, paperNativeTheme } from '../../core/theme';
import ShippingChannelConfigInput from '../../components/ShippingChannelConfigInput';
import { useApolloClient } from '@apollo/client';
import { ShippingChannelStore } from '../../store/ShippingChannelStore';
import { useIsMobile } from '../../core/responsive.utils';
import { Modal, Portal } from 'react-native-paper';
import AddPrinterSettingForm from '../../components/AddPrinterSettingForm';
import PrinterSettingEditModal from '../../components/PrinterSettingEditModal';
import qz from 'qz-tray';
import { ReconnectButton } from '../../components/ReconnectButton';

const booleanFormatter = (key, item) => {
  return item[key] ? (
    <Icon
      name="checkmark-circle-2-outline"
      style={{ width: 22, fill: paperNativeTheme.colors.success }}
    />
  ) : (
    <Icon
      name="checkmark-circle-2-outline"
      style={{ width: 22, fill: paperNativeTheme.colors.error }}
    />
  );
};

export const EditWmsAccountScreen = observer(({ navigation, route }) => {
  const warehouseAccountId = route?.params?.id;
  const client = useApolloClient();
  const isMobile = useIsMobile();

  const shippingChannelStore = useMemo(
    () => new ShippingChannelStore(client, warehouseAccountId),
    [warehouseAccountId, client],
  );

  const shippingChannels = useMemo(
    () => shippingChannelStore.shippingChannels,
    [shippingChannelStore.shippingChannels],
  );

  // if fails to find id, display the error
  if (!warehouseAccountId) {
    return (
      <Background fullWidth={true}>
        <Text>We can not find the account details that you requested. Please try again later.</Text>
        <Text style={[styles.link]} onPress={() => navigation.navigate('Home')}>
          Return to the Home page.
        </Text>
      </Background>
    );
  }
  const warehouseAccount = warehouseAccountStore.warehouseAccounts.find(
    (w) => w.id === Number(warehouseAccountId),
  );

  const initialValues = useMemo(
    () => ({
      name: warehouseAccount?.name,
      accountNumber: warehouseAccount?.accountNumber,
    }),
    [warehouseAccount],
  );

  const [deleteWarehouse, setDeleteWarehouse] = useState(null);
  const [deleteShippingChannel, setDeleteShippingChannel] = useState(null);

  const [printerDevices, setPrinterDevices] = useState([]);
  const getPrinterDevices = async () => {
    await qz.websocket.connect();
    const printers = await qz.printers.find();
    setPrinterDevices(printers);
    qz.websocket.disconnect();
  };
  useEffect(() => {
    getPrinterDevices();
  }, []);
  const warehouses = warehousesStore.warehousesByAccountId[warehouseAccountId] || [];
  printerSettingStore.setwarehouseAccountId(warehouseAccountId);
  printerSettingStore.setWarehouseCodes(warehouses.map((wh) => wh.warehouse_code));

  const checkShowReconnect = ({ data }) => {
    const check = LIST_WAREHOUSE_ALLOW_RECONNECT.includes(data.provider);
    return check && (data.isReconnected === true || data.connectionError);
  };

  return (
    <UIStatusWrapper
      status={{
        error: warehouseAccountStore.error,
        indeterminate: !warehouseAccount,
      }}>
      <ScrollView
        contentContainerStyle={{
          minHeight: '100%',
          overflowY: 'auto',
        }}>
        <Card>
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            validationSchema={Yup.object({
              name: Yup.string().required(),
              accountNumber: Yup.string().trim().min(1),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              await warehouseAccountStore.updateWmsAccount(
                warehouseAccount.id,
                values.name,
                values.accountNumber,
              );
              setSubmitting(false);
              navigation.navigateBack();
            }}>
            {(props) => {
              const Footer = (footerProps) => (
                <View {...footerProps}>
                  <Button
                    loading={props.isSubmitting}
                    disabled={warehouseAccountStore.loading || !props.isValid}
                    style={styles.footerControl}
                    size="small"
                    onPress={() => props.handleSubmit()}>
                    Save
                  </Button>
                </View>
              );
              return (
                <Card
                  style={styles.card}
                  header={(props) => (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                      {...props}>
                      <Text category="h6">{'WMS account details'}</Text>
                      {checkShowReconnect({ data: warehouseAccount }) && (
                        <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                          <ReconnectButton
                            showInfo
                            reconnectHandle={async () => {
                              await warehouseAccountStore.reconnectWarehouse4PX({
                                warehouseAccount,
                                forceReconnect: true,
                              });
                            }}
                          />
                        </View>
                      )}
                    </View>
                  )}
                  footer={Footer}>
                  <TextInputField
                    label="Name"
                    name="name"
                    style={styles.field}
                    placeholder="Account name"
                    {...props}
                  />
                  <TextInputField
                    disabled={warehouseAccount?.provider === WAREHOUSE_PROVIDERS.EZWMS}
                    label="Account number"
                    name="accountNumber"
                    style={styles.field}
                    placeholder="Account number"
                    {...props}
                  />
                </Card>
              );
            }}
          </Formik>
        </Card>

        {warehouseAccount?.provider === WAREHOUSE_PROVIDERS.EZWMS ? (
          <View>
            <Card
              style={styles.card}
              header={(props) => (
                <Layout {...props} style={styles.addLocationFormContainer}>
                  <Text category="h6">{'Warehouse locations'}</Text>
                  <Button
                    disabled={warehousesStore.loading}
                    size="small"
                    appearance="outline"
                    onPress={() => {
                      const modalID = ModalService.show(
                        <WarehouseLocationInput
                          warehouseCodeEditable={true}
                          allowDuplicateName={false}
                          title="Add warehouse location"
                          onSubmit={async (values) => {
                            try {
                              await warehousesStore.addWarehouse(
                                warehouseAccount.id,
                                values.code,
                                values.name,
                                values.country,
                                values.state,
                                values.city,
                                values.district,
                                values.postCode,
                                values.street,
                                values.contact,
                                values.remark,
                                values.aisles,
                                values.units_per_aisle,
                                values.shelves_per_unit,
                                values.sections_per_shelf,
                              );
                              ModalService.hide(modalID);
                              warehousesStore.fetchItems();
                            } catch (e) {
                              unifiedAlert(e);
                            }
                          }}
                          onCancel={() => ModalService.hide(modalID)}
                        />,
                        {
                          onBackdropPress: ModalService.hide(modalID),
                          backdropStyle: {
                            backgroundColor: ezTheme.backdropModalColor,
                          },
                        },
                      );
                    }}>
                    Add location
                  </Button>
                </Layout>
              )}>
              <InlineTable
                items={warehouses}
                columnByKey={{
                  warehouse_code: { title: 'Code', size: 'medium' },
                  warehouse_name_en: { title: 'Name' },
                  city: { title: 'City' },
                  country: { title: 'Country', size: 'medium' },
                  street: {
                    title: 'Address',
                    formatter: (key, item) => `${item.street} ${item.district} ${item.state}`,
                  },
                  contact: { title: 'Contact' },
                }}
                onRemoveItem={(index) => {
                  setDeleteWarehouse(warehouses[index]);
                }}
                onEditItem={(index) => {
                  const editWarehouse = warehouses[index];
                  const modalID = ModalService.show(
                    <WarehouseLocationInput
                      warehouse={editWarehouse}
                      warehouseCodeEditable={false}
                      allowDuplicateName={true}
                      title={`Edit warehouse location: ${editWarehouse.warehouse_code}`}
                      onSubmit={async (values) => {
                        try {
                          await warehousesStore.setWarehouse(
                            editWarehouse.id,
                            warehouseAccount.id,
                            values.name,
                            values.country,
                            values.state,
                            values.city,
                            values.district,
                            values.postCode,
                            values.street,
                            values.contact,
                            values.remark,
                            values.aisles,
                            values.units_per_aisle,
                            values.shelves_per_unit,
                            values.sections_per_shelf,
                          );
                          ModalService.hide(modalID), warehousesStore.fetchItems();
                        } catch (e) {
                          unifiedAlert(e);
                        }
                      }}
                      onCancel={() => {
                        ModalService.hide(modalID);
                      }}
                    />,
                    {
                      onBackdropPress: ModalService.hide(modalID),
                      backdropStyle: {
                        backgroundColor: ezTheme.backdropModalColor,
                      },
                    },
                  );
                }}
              />
            </Card>
            <Card
              style={[styles.card, { paddingBottom: 100 }]}
              header={(props) => (
                <Layout {...props} style={styles.addLocationFormContainer}>
                  <Text category="h6">{'Shipping channels'}</Text>
                  <Button
                    disabled={warehousesStore.loading}
                    size="small"
                    appearance="outline"
                    onPress={() => {
                      const modalID = ModalService.show(
                        <ShippingChannelConfigInput
                          warehouseAccountId={warehouseAccountId}
                          channelNameEditable={true}
                          allowDuplicateName={false}
                          title="Add shipping channel"
                          onSubmit={async (values) => {
                            const duplicateChannel = shippingChannels.find(
                              (c) => c.name === values.name,
                            );
                            if (duplicateChannel) {
                              throw new Error(
                                `Shipping channel already exists with the name ${values.name} `,
                              );
                            }
                            await shippingChannelStore.addShippingChannel({
                              warehouseAccountId: warehouseAccount.id,
                              ...values,
                            });
                            ModalService.hide(modalID);
                            shippingChannelStore.fetchItems();
                          }}
                          onCancel={() => ModalService.hide(modalID)}
                        />,
                        {
                          onBackdropPress: ModalService.hide(modalID),
                          backdropStyle: {
                            backgroundColor: ezTheme.backdropModalColor,
                          },
                        },
                      );
                    }}>
                    Add shipping channel
                  </Button>
                </Layout>
              )}>
              <InlineTable
                style={{
                  maxHeight: 300,
                  overflowY: 'auto',
                }}
                items={shippingChannels}
                columnByKey={{
                  provider: { title: 'Provider' },
                  name: { title: 'Name' },
                  warehouse_code: { title: 'Warehouse' },
                  autoPack: {
                    title: 'Auto-pack',
                    formatter: booleanFormatter,
                  },
                  supportPacking: { title: 'Suppot packing', formatter: booleanFormatter },
                  singlePackageOnly: { title: 'Single package', formatter: booleanFormatter },
                  requireExportInfo: { title: 'Export info required', formatter: booleanFormatter },
                  allowUserShipmentOrder: {
                    title: 'Allow shipment order',
                    formatter: booleanFormatter,
                  },
                }}
                onRemoveItem={(index) => {
                  setDeleteShippingChannel(shippingChannels[index]);
                }}
                onEditItem={(index) => {
                  const editChannelConfig = shippingChannels[index];
                  const modalID = ModalService.show(
                    <ShippingChannelConfigInput
                      warehouseAccountId={warehouseAccountId}
                      channelConfig={editChannelConfig}
                      channelNameEditable={false}
                      allowDuplicateName={true}
                      title={`Edit shipping channel: ${editChannelConfig.name}`}
                      onSubmit={async (values) => {
                        await shippingChannelStore.updateShippingChannel({
                          id: editChannelConfig.id,
                          warehouseAccountId: warehouseAccount.id,
                          ...values,
                        });
                        shippingChannelStore.fetchItems();
                        ModalService.hide(modalID);
                      }}
                      onCancel={() => {
                        ModalService.hide(modalID);
                      }}
                    />,
                    {
                      onBackdropPress: ModalService.hide(modalID),
                      backdropStyle: {
                        backgroundColor: ezTheme.backdropModalColor,
                      },
                    },
                  );
                }}
              />
            </Card>
          </View>
        ) : null}

        <Card
          style={[styles.card]}
          header={(props) => (
            <Layout {...props} style={styles.addLocationFormContainer}>
              <Text category="h6">{'Printer setting'}</Text>
              <Button
                disabled={printerSettingStore.loading}
                size="small"
                appearance="outline"
                onPress={() => {
                  printerSettingStore.setAddPrinterSettingFormVisible(true);
                }}>
                Add printer setting
              </Button>
            </Layout>
          )}>
          <InlineTable
            items={printerSettingStore.items}
            columnByKey={{
              courierLabel: { title: 'Courier Label' },
              printerName: { title: 'Printer Name' },
              paperSize: { title: 'Paper size' },
              orientation: {
                title: 'Orientation',
              },
              warehouseCode: {
                title: 'Warehouse Code',
              },
            }}
            onRemoveItem={(index) => {
              unifiedConfirm(
                'Are you sure you want to delete the setting?',
                'Setting cannot be used after submission.',
                async () => {
                  try {
                    await printerSettingStore.deleteItem(
                      printerSettingStore.items[index].id,
                      printerSettingStore.items[index].warehouseCode,
                    );
                    printerSettingStore.fetchItems();
                  } catch (err) {
                    unifiedAlert(err);
                  }
                },
              );
            }}
            onEditItem={(index) => {
              printerSettingStore.toggleOpenEditPrinterSettingModal(true);
              printerSettingStore.setSelectedSetting(printerSettingStore.items[index]);
            }}
          />
        </Card>

        <DeleteConfirmationModal
          visible={deleteWarehouse}
          title={`Delete warehouse: ${deleteWarehouse?.warehouse_code}`}
          warningMessage="this will erase all inventory and SKU data associated with this warehouse location"
          onSubmit={async () => {
            try {
              await warehousesStore.deleteWarehouse(
                warehouseAccount.id,
                deleteWarehouse.warehouse_code,
              );
              warehousesStore.fetchItems();
            } catch (e) {
              unifiedAlert(JSON.stringify(e));
            }
            setDeleteWarehouse(null);
          }}
        />

        <AddPrinterSettingModal
          printerDevices={printerDevices}
          labelList={shippingChannels}
          warehouseCodes={warehouses.map((wh) => wh.warehouse_code)}
          contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}
          visible={printerSettingStore.isOpenAddPrinterSettingForm}
          onDismiss={() => printerSettingStore.setAddPrinterSettingFormVisible(false)}
          onSubmit={() => {
            printerSettingStore.setAddPrinterSettingFormVisible(false);
          }}
        />

        <DeleteConfirmationModal
          visible={!!deleteShippingChannel}
          onCancel={() => setDeleteShippingChannel(false)}
          title={`Delete shipping channel: ${deleteShippingChannel?.name}`}
          warningMessage="this will affect all historical shipments associated with this shipping channel"
          onSubmit={async () => {
            try {
              await shippingChannelStore.deleteShippingChannel(
                deleteShippingChannel.id,
                warehouseAccount.id,
              );
              warehouseAccountStore.fetchItems();
            } catch (e) {
              unifiedAlert(JSON.stringify(e));
            }
            setDeleteShippingChannel(null);
          }}
        />

        <PrinterSettingEditModal
          printerDevices={printerDevices}
          labelList={shippingChannels}
          visible={printerSettingStore.isOpenEditPrinterSettingModal}
          setVisible={printerSettingStore.toggleOpenEditPrinterSettingModal}
          setting={printerSettingStore.selectedSetting}
          onSubmit={() => {
            printerSettingStore.fetchItems();
            printerSettingStore.toggleOpenEditPrinterSettingModal(false);
          }}
        />
      </ScrollView>
    </UIStatusWrapper>
  );
});

const AddPrinterSettingModal = observer(
  ({
    warehouseAccountId,
    contentContainerStyle,
    visible,
    onDismiss,
    onSubmit,
    warehouseCodes,
    labelList,
    printerDevices,
  }) => {
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          <AddPrinterSettingForm
            printerDevices={printerDevices}
            labelList={labelList}
            warehouseAccountId={warehouseAccountId}
            warehouseCodes={warehouseCodes}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
          />
        </Modal>
      </Portal>
    );
  },
);

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  footerControl: {
    marginTop: '0.5em',
  },
  card: {
    flex: 1,
    margin: 2,
    height: 500,
    paddingBottom: 10,
  },
  addLocationFormContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginVertical: 5,
    alignItems: 'center',
  },
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: { backgroundColor: 'white', marginHorizontal: '40%', minWidth: 600 },
  buttonContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});
