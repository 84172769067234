import React, { useCallback, useState } from 'react';
import { Card, Modal, Portal } from 'react-native-paper';
import { List, ListItem } from '@ui-kitten/components';
import { Linking, StyleSheet, View } from 'react-native';
import SkuSearchForm from '../../components/SkuSearchForm';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import AddSkuForm, { getSkuSchema } from '../../components/AddSkuForm';
import Table from '../../components/Table';
import Background from '../../components/Background';
import { useIsMobile } from '../../core/responsive.utils';
import CsvUploader from '../../components/CsvUploader';
import TableControl from '../../components/SkuTableControl';
import { UIStatusWrapper } from '../../components/ui-status';
import { useNavigation } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/core';
import exportCsv from '../../core/csv';
import {
  countryStateCityCurrencyStore,
  inventoryStore,
  skuTableStore,
  warehouseAccountStore,
  warehousesStore,
} from '../../store';
import { Button, Divider, Icon, Layout, Spinner, Text } from '@ui-kitten/components';
import { zipObj, reverse, omit } from 'ramda';
import { SKU_CODES_EXIST, unifiedAlert } from '../../core/utils/utils';
import { useApolloClient } from '@apollo/client';
import { ezTheme, paperNativeTheme } from '../../core/theme';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import Dialogue from '../../components/Dialogue';
const getId = (item) => item.sku_id;
const selectedSkus = (list, selectedIds) => list?.filter((i) => selectedIds.has(getId(i)));

const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;

export default observer(() => {
  const navigation = useNavigation();
  // const navigation = useNavigation();
  const isMobile = useIsMobile();
  const client = useApolloClient();
  // Refetch data when screen is focused
  const isFocused = useIsFocused();
  React.useEffect(() => {
    if (isFocused) {
      skuTableStore.fetchItems();
    }
  }, [isFocused]);

  const addSKUsBatch = async (data) => {
    if (countryStateCityCurrencyStore.countryCodes.length === 0) {
      await countryStateCityCurrencyStore.fetchCountries();
    }

    const schema = getSkuSchema(
      countryStateCityCurrencyStore.currencies,
      countryStateCityCurrencyStore.countryCodes,
      inventoryStore.getUoms(warehouseAccountStore.selectedWarehouseAccount.id),
      warehouseAccountStore.warehouseAccountIds,
    );
    const skus = data.map((d) => {
      const sku = omit(
        ['undefined'],
        zipObj(
          Object.keys(d).map((k) => CSV_HEADERS.find((h) => h.displayKey === k)?.key),
          Object.values(d),
        ),
      );

      sku.product_code = sku.product_code?.toString();
      sku.logistics_package = sku.logistics_package === 'N' ? false : true;
      const exporterKeys = Object.keys(d).filter((key) => key.match(/^Exporter\d+$/) && !!d[key]);
      const declare_country_list = exporterKeys.map((key) => {
        const index = key.replace('Exporter', '');
        const exportCountryLabel = key;
        const exportPortLabel = `Export port${index}`;
        const exportDeclareLabel = `Name of export declared goods${index}`;
        const exportHscodeLabel = `Customer export customs code${index}`;
        const exportDeclareValue = `Export declared value${index}`;
        const exportCurrentyLabel = `Export Currency type${index}`;
        const importCounryLabel = `Importing country${index}`;
        const importPortLabel = `Import port${index}`;
        const importDeclareLabel = `Name of import declared product${index}`;
        const importHscodeLabel = `Customer import customs code${index}`;
        const importDeclareValueLabel = `Import declared value${index}`;
        const importCurrentyLabel = `Import Currency type${index}`;
        return {
          export_country: d[exportCountryLabel],
          export_port: d[exportPortLabel],
          export_declare: d[exportDeclareLabel],
          export_hscode: d[exportHscodeLabel],
          export_declare_value: d[exportDeclareValue],
          export_currency: d[exportCurrentyLabel],
          country: d[importCounryLabel],
          import_port: d[importPortLabel],
          import_declare: d[importDeclareLabel],
          hs_code: d[importHscodeLabel],
          declare_value: d[importDeclareValueLabel]?.toString(),
          currency: d[importCurrentyLabel],
        };
      });
      return { ...sku, declare_country_list };
    });
    const errors = [];
    await Promise.all(
      skus.map((s, i) =>
        schema.validate(s).catch((e) => {
          errors.push([i, e]);
        }),
      ),
    );
    if (errors.length > 0) {
      unifiedAlert(
        reverse(errors)
          .map(([i, e]) => `Row ${i + 1}: ${e.message}`)
          .join('\n'),
      );
      return;
    }
    const {
      data: { skuCodesExist },
    } = await client.query({
      query: SKU_CODES_EXIST,
      variables: {
        warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount.id,
        skuCodes: skus.map((s) => s.sku_code),
      },
      fetchPolicy: 'no-cache',
    });
    if (skuCodesExist) {
      const msg = 'SKU code already exist, please use a different one';
      unifiedAlert(msg);
      return;
    }
    const productCodes = skus.map((s) => s.product_code).filter((p) => !!p);
    if (productCodes.length > 0) {
      const {
        data: { skuCodesExist: productCodesExist },
      } = await client.query({
        query: SKU_CODES_EXIST,
        variables: {
          warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount.id,
          productCodes: productCodes,
        },
        fetchPolicy: 'no-cache',
      });
      if (productCodesExist) {
        const msg = 'Product code already exist, please use a different one';
        unifiedAlert(msg);
        return;
      }
    }

    try {
      await inventoryStore.addItems(warehouseAccountStore.selectedWarehouseAccount.id, skus);
    } catch (e) {
      unifiedAlert(e.message);
    }
    await skuTableStore.fetchItems();

    skuTableStore.closeCsvModal();
  };

  const updateSKUsBatch = async (data) => {
    try {
      const skus = data.map((d) => {
        let sku = {};
        Object.entries(d).forEach(([k, v]) => {
          const fieldInfo = CSV_HEADERS_BATCH_UPDATE.find((h) => h.displayKey === k);
          sku[fieldInfo.key] = fieldInfo.dataType === 'string' ? `${v}` : v;
        });
        return sku;
      });
      await inventoryStore.batchUpdateSKUs({
        warehouseAccountId: warehouseAccountStore.selectedWarehouseAccount.id,
        updateData: skus,
      });
      await skuTableStore.fetchItems();
      skuTableStore.closeCsvModal();
    } catch (error) {
      unifiedAlert(error.message);
    }
  };

  return (
    <Background fullWidth={true}>
      <TableControl
        disableSearch={skuTableStore.dataStore.loading || skuTableStore.loading}
        requireButtonClickToSearch={skuTableStore.total > MAX_NO_OF_ITEMS_AUTO_SEARCH}
        filterParams={skuTableStore.searchParams}
        filterOptions={skuTableStore.SHORTCUT_OPTIONS}
        indicators={skuTableStore.filterIndicators}
        onSearchTermChange={skuTableStore.setSearchTerm}
        onFilterChange={skuTableStore.setSearchParams}
        onFilterClear={skuTableStore.clearSearchParams}
        onPressAdvancedSearch={skuTableStore.openSearch}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: skuTableStore.dataStore.error || skuTableStore.error,
            indeterminate: skuTableStore.dataStore.loading || skuTableStore.loading,
          }}>
          <Table
            heightOffset={320}
            items={skuTableStore.filteredItems}
            getId={getId}
            displayKeys={skuTableStore.DISPLAY_KEYS}
            titleByKey={skuTableStore.TITLE_BY_KEY}
            formatterByKey={{
              weight: weightGramToKg(false),
              warehouse_code: (w) => warehousesStore.getWarehouseNameEn(w),
              dimension: (_, sku) => `${sku.length}L*${sku.width}W*${sku.height}H`,
            }}
            rowOnClick={(item) =>
              navigation.navigate('SkuDetail', {
                id: item.sku_code,
                warehouseAccountId: item.warehouseAccountId,
              })
            }
            isFabVisible={isFocused}
            currentPage={skuTableStore.currentPage}
            onPageChange={skuTableStore.setCurrentPage}
            totalItemNum={skuTableStore.total}
            itemsPerPage={skuTableStore.pageSize}
            setPageSize={skuTableStore.setPageSize}
            fabActions={(selectedIds) => {
              const actions = [];

              if (!isMobile && selectedIds.size === 0) {
                actions.push({
                  icon: 'file-upload-outline',
                  label: 'Upload SKUs in batch',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      skuTableStore.openCsvModal();
                      skuTableStore.setIsBatchUpdate(false);
                    } else {
                      skuTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        skuTableStore.openCsvModal();
                        skuTableStore.setIsBatchUpdate(false);
                      });
                      skuTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'pencil',
                  label: 'Update SKUs in batch',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      skuTableStore.openCsvModal();
                      skuTableStore.setIsBatchUpdate(true);
                    } else {
                      skuTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        skuTableStore.openCsvModal();
                        skuTableStore.setIsBatchUpdate(true);
                      });
                      skuTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
              }
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all to CSV',
                  onPress: async () => {
                    await skuTableStore.fetchItems(true);
                    // extract ALL attributes from skuTableStore.skuList
                    // these csvHeaders can be extended to have nested attributes
                    // The nested structures must be pre-defined.
                    const csvHeaders =
                      skuTableStore.exportedItems.length > 0
                        ? Object.keys(toJS(skuTableStore.exportedItems[0]))
                        : [];
                    exportCsv(skuTableStore.exportedItems, csvHeaders, 'skulist.csv');
                  },
                });
                actions.push({
                  icon: 'plus',
                  label: 'Add SKU',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      skuTableStore.openSkuForm();
                    } else {
                      skuTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        skuTableStore.openSkuForm();
                      });
                      skuTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
                actions.push({
                  icon: 'sync',
                  label: 'Sync SKUs',
                  onPress: () => skuTableStore.setSyncModalVisible(true),
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'export',
                  label: `Export selected ${selectedIds.size > 1 ? 'SKUs' : 'SKU'} to CSV`,
                  onPress: () => {
                    const skus = selectedSkus(skuTableStore.filteredItems, selectedIds);
                    const csvHeaders = skus.length > 0 ? Object.keys(toJS(skus[0])) : [];
                    exportCsv(skus, csvHeaders, 'skulist.csv');
                  },
                });

                actions.push({
                  icon: 'trash-can-outline',
                  label: `Delete selected ${selectedIds.size > 1 ? 'SKUs' : 'SKU'}`,
                  onPress: () => {
                    skuTableStore.skusToDelete = selectedIds;
                    skuTableStore.openDeleteSkusModal();
                  },
                });
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>

      <DeleteSkusModalOpen
        visible={skuTableStore.deleteSkusModalOpen}
        onDismiss={skuTableStore.closeDeleteSkusModal}
        onSubmit={async () => {
          try {
            await skuTableStore.deleteItems(
              warehouseAccountStore.getWarehouseAccountIds(),
              skuTableStore.skusToDelete,
            );
          } catch (error) {
            unifiedAlert(error);
          }
          skuTableStore.fetchItems();
          skuTableStore.closeDeleteSkusModal();
        }}>
        <View>
          <Text style={{ paddingBottom: 10 }}>Are you sure you want to delete the SKU(s)?</Text>
          <List
            style={styles.listContainerStyle}
            data={selectedSkus(skuTableStore.filteredItems, skuTableStore.skusToDelete)}
            renderItem={({ item }) => {
              return <ListItem title={item.sku_code} description={item.sku_name} />;
            }}
          />
        </View>
      </DeleteSkusModalOpen>

      <SkuSearchModal
        contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        visible={skuTableStore.searchOpen}
        onDismiss={skuTableStore.closeSearch}
        searchParams={{
          productName: skuTableStore.searchParams.productName,
          skus: (skuTableStore.searchParams.skus || []).join('\n'),
          warehouses: (skuTableStore.searchParams.warehouses || []).join('\n'),
          minSaleableStock: skuTableStore.searchParams.saleable_stock?.min,
          maxSaleableStock: skuTableStore.searchParams.saleable_stock?.max,
          minOnwayStock: skuTableStore.searchParams.onway_stock?.min,
          maxOnwayStock: skuTableStore.searchParams.onway_stock?.max,
          minWeight: skuTableStore.searchParams.weight?.min,
          maxWeight: skuTableStore.searchParams.weight?.max,
          minLength: skuTableStore.searchParams.length?.min,
          maxLength: skuTableStore.searchParams.length?.max,
          minWidth: skuTableStore.searchParams.width?.min,
          maxWidth: skuTableStore.searchParams.width?.max,
          minHeight: skuTableStore.searchParams.height?.min,
          maxHeight: skuTableStore.searchParams.height?.max,
        }}
        onSubmit={(values) => {
          const {
            skus,
            productName,
            minSaleableStock,
            maxSaleableStock,
            minOnwayStock,
            maxOnwayStock,
            minWeight,
            maxWeight,
            minLength,
            maxLength,
            minHeight,
            maxHeight,
            minWidth,
            maxWidth,
            warehouses,
          } = values;
          const skuCodes = skus ? skus.split('\n').filter((id) => id !== '') : [];
          const warehouseCodes = warehouses ? warehouses.split('\n').filter((id) => id !== '') : [];

          let params = {
            productName,
            skus: skuCodes,
            warehouses: warehouseCodes,
            saleable_stock: { min: minSaleableStock, max: maxSaleableStock },
            onway_stock: { min: minOnwayStock, max: maxOnwayStock },
            weight: {
              min: minWeight,
              max: maxWeight,
            },
            length: {
              min: minLength,
              max: maxLength,
            },
            width: {
              min: minWidth,
              max: maxWidth,
            },
            height: {
              min: minHeight,
              max: maxHeight,
            },
          };

          skuTableStore.setSearchParams(params);
          skuTableStore.closeSearch();
        }}
      />
      <SkuModal
        contentContainerStyle={isMobile ? styles.mobileModalStyle : styles.modalStyle}
        visible={skuTableStore.skuFormOpen}
        onDismiss={skuTableStore.closeSkuForm}
        onSubmit={() => {
          skuTableStore.closeSkuForm();
          // refetchInventories();
        }}
      />
      <SyncSkuModal
        visible={skuTableStore.syncModalVisisble}
        setVisible={skuTableStore.setSyncModalVisible}
      />
      <CsvUploadModal
        templatePath={
          skuTableStore.isBatchUpdate
            ? require('../../spreadsheetTemplates/BatchUpdateSKUs.xlsx')
            : require('../../spreadsheetTemplates/SkuRegisterEn.xls')
        }
        contentContainerStyle={styles.mobileModalStyle}
        visible={skuTableStore.csvModalOpen}
        onDismiss={skuTableStore.closeCsvModal}
        onSubmit={skuTableStore.isBatchUpdate ? updateSKUsBatch : addSKUsBatch}
      />
      <WarehouseAccountSelectionModal
        visible={skuTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          skuTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          skuTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => skuTableStore.setWarehouseSelectionVisible(false)}
      />
    </Background>
  );
});

const SkuModal = observer(({ contentContainerStyle, visible, onDismiss, onSubmit }) => (
  <Portal>
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={contentContainerStyle}>
      <AddSkuForm
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        warehouseAccount={warehouseAccountStore.selectedWarehouseAccount}
      />
    </Modal>
  </Portal>
));

const CSV_HEADERS = [
  { key: 'warehouseAccountId', displayKey: 'Warehouse Account ID' },
  { key: 'sku_code', displayKey: 'SKU' },
  { key: 'sku_name', displayKey: 'Description (Eng)' },
  { key: 'chinese_name', displayKey: 'Description (Chn)' },
  { key: 'product_code', displayKey: 'Product Code' },
  { key: 'weight', displayKey: 'Registered Weight(KG)' },
  { key: 'length', displayKey: 'Registered Length(CM)' },
  { key: 'width', displayKey: 'Registered Width(CM)' },
  { key: 'height', displayKey: 'Registered Height(CM)' },
  { key: 'is_brand', displayKey: 'Is Product Branded' },
  { key: 'brand_name', displayKey: 'Brand name' },
  { key: 'origin_country', displayKey: 'Country of Origin' },
  { key: 'logistics_package', displayKey: 'Logistics package' },
  { key: 'specification', displayKey: 'Specification' },
  { key: 'material', displayKey: 'Material' },
  { key: 'uses', displayKey: 'Usage' },
  { key: 'include_battery', displayKey: 'With Battery' },
];

const CSV_HEADERS_BATCH_UPDATE = [
  { key: 'sku_code', displayKey: 'SKU code', dataType: 'string' },
  { key: 'product_code', displayKey: 'Product code', dataType: 'string' },
  { key: 'uom', displayKey: 'Unit of Measure', dataType: 'string' },
  { key: 'weight', displayKey: 'Weight (kg)' },
  { key: 'length', displayKey: 'Length (cm)' },
  { key: 'width', displayKey: 'Width (cm)' },
  { key: 'height', displayKey: 'Height (cm)' },
];

const CsvUploadModal = observer(
  ({ contentContainerStyle, visible, onDismiss, onSubmit, templatePath }) => {
    return (
      <Portal>
        <Modal
          visible={visible}
          onDismiss={onDismiss}
          contentContainerStyle={contentContainerStyle}>
          <CsvUploader
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            sheetIndices={[0]}
            onUpload={(csv) => {
              const skuCodeMap = {};
              const productCodeMap = {};

              for (let row of csv) {
                const SKU = row['SKU'];
                const productCode = row['Product Code'];

                if (skuCodeMap[SKU] && !skuTableStore.isBatchUpdate) {
                  unifiedAlert('There are duplciate SKU codes in the uploaded file.');
                  return false;
                } else {
                  skuCodeMap[SKU] = true;
                }

                if (productCodeMap[productCode] && !skuTableStore.isBatchUpdate) {
                  unifiedAlert('There are duplciate Product codes in the uploaded file.');
                  return false;
                } else {
                  productCodeMap[productCode] = true;
                }
              }

              return true;
            }}
          />
          <Button
            appearance="ghost"
            onPress={() => {
              Linking.openURL(templatePath);
            }}>
            Download template
          </Button>
        </Modal>
      </Portal>
    );
  },
);

const DeleteSkusModalOpen = ({ visible, onDismiss, onSubmit, children }) => (
  <Portal>
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalStyle}>
      <Dialogue onSubmit={onSubmit} onDismiss={onDismiss}>
        {children}
      </Dialogue>
    </Modal>
  </Portal>
);

const SkuSearchModal = observer(
  ({ contentContainerStyle, visible, onDismiss, searchParams, onSubmit }) => (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={contentContainerStyle}>
        <SkuSearchForm searchParams={searchParams} onSubmit={onSubmit} />
      </Modal>
    </Portal>
  ),
);

const SyncSkuModal = observer(({ visible, setVisible }) => {
  const [isSyncing, setIsSyncing] = useState(false);

  const onStart = useCallback(async () => {
    setIsSyncing(true);
    try {
      await skuTableStore.syncSkus();
    } catch (e) {
      console.error(e);
    }
    setIsSyncing(false);
    if (!skuTableStore.dataStore.syncSkuError) {
      setVisible(false);
    }
  }, [setIsSyncing]);

  const onDismiss = () => {
    setVisible(false);
    setIsSyncing(false);
  };

  return (
    <Portal>
      <Modal
        visible={visible}
        backdropStyle={{
          backgroundColor: ezTheme.backdropModalColor,
        }}
        onDismiss={onDismiss}
        contentContainerStyle={styles.modalStyle}>
        <Layout style={{ margin: 20 }}>
          <Text category="h6" style={{ marginBottom: 20 }}>
            Sychronizing SKUs from warehouse
          </Text>
          <Divider />
          <Layout style={{ flexDirection: 'row', alignItems: 'center' }}>
            {isSyncing ? (
              <Button
                appearance="ghost"
                size="small"
                accessoryLeft={() => <Spinner size="medium" />}
              />
            ) : skuTableStore.dataStore.syncSkuError ? (
              <Text category="p2" status="danger">
                {skuTableStore.dataStore.syncSkuError.message}
              </Text>
            ) : (
              <Button
                appearance="ghost"
                size="small"
                accessoryLeft={() => (
                  <Icon
                    name="checkmark-circle-2-outline"
                    style={{ width: 22, fill: paperNativeTheme.colors.success }}
                  />
                )}
              />
            )}
            <Text category="p2" status="warning" style={{ marginHorizontal: 20 }}>
              Depending on the number of your SKUs, this may take 1 ~ 10 minites to finish
            </Text>
          </Layout>
          <Divider />
          <Layout style={styles.buttonContainer}>
            <Button status="primary" onPress={onStart} style={styles.button} disabled={isSyncing}>
              Start sync
            </Button>
            <Button status="control" onPress={onDismiss} style={styles.button} disabled={isSyncing}>
              Close
            </Button>
          </Layout>
        </Layout>
      </Modal>
    </Portal>
  );
});

const styles = StyleSheet.create({
  mobileModalStyle: { backgroundColor: 'white', marginHorizontal: 20 },
  modalStyle: {
    backgroundColor: 'white',
    padding: 20,
    alignSelf: 'center',
    overflowY: 'auto',
    maxHeight: '70%',
    marginHorizontal: '40%',
    minWidth: 600,
  },
  buttonContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  listContainerStyle: {
    maxHeight: 300,
    overflow: 'auto',
  },
});
