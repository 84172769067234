import { Card, Modal as UIKittenModal } from '@ui-kitten/components';
import React from 'react';
import { ezTheme } from '../core/theme';

export const Modal = ({ visible, children, width, height, onBackdropPress, contentStyle }) => {
  return (
    <UIKittenModal
      visible={visible}
      onBackdropPress={onBackdropPress}
      backdropStyle={{ backgroundColor: ezTheme.backdropModalColor }}>
      <Card style={[{ width, height }, contentStyle]}>{children}</Card>
    </UIKittenModal>
  );
};
