import { gql } from '@apollo/client';
import { makeAutoObservable } from 'mobx';

const DEFAUL_PARAMS = {
  pageNo: undefined,
  pageSize: undefined,
  id: undefined,
  country: undefined,
  state: undefined,
  city: undefined,
};

const QUERY = gql`
  query listAddress(
    $searchTerm: String
    $pageSize: Int
    $pageNo: Int
    $country: String
    $state: String
    $city: String
  ) {
    listAddress(
      searchTerm: $searchTerm
      pageNo: $pageNo
      pageSize: $pageSize
      country: $country
      state: $state
      city: $city
    ) {
      total
      addressBook {
        id
        code
        country
        city
        state
        name
        email
        phone
        address
        house_number
        company
        post_code
      }
    }
  }
`;

export class AddressBookTableStore {
  filterParams = DEFAUL_PARAMS;
  sortOption = null;
  searchTerm = '';
  loading = true;
  error = null;
  cachedItems = null;
  currentPage = 0;
  pageSize = 20;
  total = null;
  DISPLAY_KEYS = [
    'id',
    'code',
    'country',
    'state',
    'city',
    'post_code',
    'name',
    'phone',
    'company',
  ];
  TITLE_BY_KEY = {
    id: 'ID',
    code: 'Code',
    country: 'Country',
    state: 'State',
    city: 'City',
    post_code: 'Post code',
    name: 'Name',
    phone: 'Phone number',
    company: 'Company',
  };
  get FORMATTER_BY_KEY() {
    return {};
  }
  constructor(client) {
    this.dataSource = [];
    this.client = client;

    makeAutoObservable(
      this,
      {
        dataStore: false,
        client: false,
      },
      { autoBind: true },
    );
  }

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.searchTerm = '';
    this.cachedItems = null;
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.cachedItems = null;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
  };

  async fetchItems() {
    try {
      this.loading = true;
      if (this.searchTerm) {
        const result = await this.client.query({
          query: QUERY,
          variables: {
            ...this.filterParams,
            searchTerm: this.searchTerm,
          },
          fetchPolicy: 'no-cache',
        });
        const { addressBook, total } = result.data.listAddress;
        this.cachedItems = addressBook;
        this.total = total;
      } else {
        const result = await this.client.query({
          query: QUERY,
          variables: {
            ...this.filterParams,
            pageSize: this.pageSize,
            pageNo: this.currentPage + 1,
          },
          fetchPolicy: 'no-cache',
        });
        const { addressBook, total } = result.data.listAddress;
        this.cachedItems = addressBook;
        this.total = total;
      }
      this.error = null;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }
  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }
}
