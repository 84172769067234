import { View, StyleSheet } from 'react-native';
import { Avatar, Card, List } from 'react-native-paper';
import React, { memo } from 'react';
import { useIsMobile } from 'src/core/responsive.utils';
import { Divider, Text } from '@ui-kitten/components';
import Table from './Table';
import { STATUS_COLORS } from '../core/theme.jsx';

/**
 * Renders the transaction details card for a list of transactions.
 *
 * This component displays the details of a transaction, including the transaction amount, status, ID, and additional information.
 * It also renders breakdowns for warehouse charges, shipping charges, and shipment charges if they are available in the transaction data.
 *
 * @param {Object} props - The component props.
 * @param {Object[]} props.transaction - A transaction objects.
 * @returns {React.ReactElement} - A React component that renders the transaction details card.
 */
const TransactionDetail = ({ transaction }) => {
  const isMobile = useIsMobile();

  /**
   * Determines whether warehouse, shipping, shipment charges should be rendered based on the transaction data.
   *
   * This function checks the `remarkExtra` property of the first transaction in the `transaction` array.
   * If the `warehouseCharge` or `warehouseCharges` property is present, it returns `true`, indicating that
   * warehouse charges should be rendered. Otherwise, it returns `false`.
   *
   * @param {Object[]} transaction - A transaction objects.
   * @returns {boolean} - `true` if warehouse charges should be rendered, `false` otherwise.
   */
  const shouldRenderWarehouseCharges =
    transaction?.remarkExtra?.warehouseCharge?.remarkExtra ||
    transaction?.remarkExtra?.warehouseCharges;
  const shouldRenderShippingCharges = transaction?.remarkExtra?.shippingCharge;
  const shouldRenderShipmentBreakdown =
    transaction?.remarkExtra?.shipmentOrderId && transaction?.remarkExtra?.charges?.length > 0;

  /**
   * Processes and aggregates warehouse processing fees from different charge sources
   *
   * @param {Object} chargesData - The warehouse charges data containing various fee breakdowns
   * @param {Object} chargesData.chargePerOutboundOrder - Order level processing fees
   * @param {Object} chargesData.skusChargeBreakdown - SKU level processing fees
   * @param {Array} chargesData.itemsChargeBreakdown - Item level processing fees
   * @returns {Array} Array of processed fee objects, each containing:
   *   - fee: string - Name of the processing fee
   *   - cost: number - Total aggregated cost for this fee
   *   - details: Array - Breakdown of charges from different sources
   */
  const processWarehouseFees = (chargesData) => {
    const processingFeesMap = new Map();

    const PROCESSING_FEE_TYPES = [
      'Inbound Processing Fee',
      'Outbound\r\r\nProcessing Fee',
      'Outbound Processing Fee',
    ];

    // Process order level fees
    const orderLevelFee = PROCESSING_FEE_TYPES.find(
      (feeType) => chargesData?.chargePerOutboundOrder?.remarkExtra?.[feeType],
    );
    if (orderLevelFee) {
      const feeName = chargesData.chargePerOutboundOrder.remarkExtra[orderLevelFee];
      const existingFee = processingFeesMap.get(feeName) || { fee: feeName, cost: 0, details: [] };
      existingFee.cost += chargesData.chargePerOutboundOrder.cost;
      existingFee.details.push({
        condition: chargesData.chargePerOutboundOrder.remarkExtra.condition,
        unit: chargesData.chargePerOutboundOrder.remarkExtra.unit,
        source: 'Order Level',
      });
      processingFeesMap.set(feeName, existingFee);
    }

    // Process SKU level fees
    const skuLevelFee = PROCESSING_FEE_TYPES.find(
      (feeType) => chargesData?.skusChargeBreakdown?.[feeType],
    );
    if (skuLevelFee) {
      const feeName = chargesData.skusChargeBreakdown[skuLevelFee];
      const existingFee = processingFeesMap.get(feeName) || { fee: feeName, cost: 0, details: [] };
      existingFee.cost +=
        chargesData.skusChargeBreakdown.chargePerSku * chargesData.skusChargeBreakdown.numberOfSkus;
      existingFee.details.push({
        numberOfSkus: chargesData.skusChargeBreakdown.numberOfSkus,
        chargePerSku: chargesData.skusChargeBreakdown.chargePerSku,
        source: 'SKU Level',
      });
      processingFeesMap.set(feeName, existingFee);
    }

    // Process item level fees
    chargesData?.itemsChargeBreakdown?.forEach((item) => {
      const itemLevelFee = PROCESSING_FEE_TYPES.find((feeType) => item[feeType]);
      if (itemLevelFee) {
        const feeName = item[itemLevelFee];
        const existingFee = processingFeesMap.get(feeName) || {
          fee: feeName,
          cost: 0,
          details: [],
        };
        const itemCost = item.costPerItem * item.quantity;
        existingFee.cost += itemCost;
        existingFee.details.push({
          skuCode: item.skuCode,
          quantity: item.quantity,
          costPerItem: item.costPerItem,
          condition: item.condition,
          unit: item.unit,
          source: 'Item Level',
        });
        processingFeesMap.set(feeName, existingFee);
      }
    });

    return Array.from(processingFeesMap.values());
  };

  /**
   * Renders the warehouse charges breakdown for a transaction.
   *
   * This function takes the `remarkExtra` object from the first transaction in the `transaction` and
   * renders a list of warehouse charges, including charge per outbound order, SKUs charge breakdown, and
   * items charge breakdown. It also displays the subtotal of the warehouse charges if available.
   *
   * @param {Object} remarkExtra - The `remarkExtra` object from the first transaction in the `transaction`.
   * @returns {React.ReactElement} - A React element representing the warehouse charges breakdown.
   */
  const renderWarehouseCharges = (remarkExtra) => {
    const chargesData = remarkExtra?.warehouseCharge?.remarkExtra ?? remarkExtra?.warehouseCharges;
    const subTotal = remarkExtra?.warehouseCharge?.cost ?? undefined;
    const processingFees = processWarehouseFees(chargesData);

    return (
      <View style={{ marginHorizontal: '1em', marginVertical: '1em' }}>
        <List.Accordion title="Warehouse Charges">
          {processingFees.length > 0 && (
            <>
              {processingFees.map((fee, index) => (
                <View key={index}>
                  <List.Item
                    title={fee.fee}
                    right={() => <Text style={styles.listItemText}>$ {fee.cost.toFixed(2)}</Text>}
                  />
                </View>
              ))}
            </>
          )}
        </List.Accordion>
        {subTotal && (
          <>
            <Divider />
            <List.Item
              title="Subtotal"
              right={() => <Text style={styles.listItemText}>$ {subTotal}</Text>}
            />
          </>
        )}
      </View>
    );
  };

  /**
   * Renders a breakdown of the shipping charges for a transaction.
   *
   * @param {Object} shippingCharge - An object containing the shipping charge details.
   * @param {Array} shippingCharge.breakdown - An array of objects representing the breakdown of the shipping charges.
   * @param {string} shippingCharge.breakdown[].name - The name of the shipping charge.
   * @param {number} shippingCharge.breakdown[].amount - The amount of the shipping charge.
   * @param {string} shippingCharge.breakdown[].condition - The condition associated with the shipping charge.
   * @param {number} [shippingCharge.cost] - The total cost of the shipping charges.
   * @returns {JSX.Element} - A React component that renders the shipping charges breakdown.
   */
  const renderShippingCharges = (shippingCharge) => {
    return (
      <View style={{ marginHorizontal: '1em', marginVertical: '1em' }}>
        <List.Accordion title="Shipping Charges Breakdown">
          <Table
            items={shippingCharge?.breakdown}
            displayKeys={['name', 'amount', 'condition']}
            titleByKey={{
              name: 'Name',
              amount: 'Amount',
              condition: 'Condition',
            }}
            formatterByKey={{
              amount: (_, item) => {
                return <Text>$ {item.amount}</Text>;
              },
            }}
          />
        </List.Accordion>
        {shippingCharge?.cost && (
          <>
            <Divider />
            <List.Item
              title="Subtotal"
              right={() => <Text style={styles.listItemText}>$ {shippingCharge?.cost}</Text>}
            />
          </>
        )}
      </View>
    );
  };

  /**
   * Renders a breakdown of the shipment charges.
   *
   * @param {Object} remarkExtra - An object containing the extra information about the transaction.
   * @param {Array} remarkExtra.charges - An array of objects representing the breakdown of the shipment charges.
   * @param {string} remarkExtra.charges[].name - The name of the shipment charge.
   * @param {number} remarkExtra.charges[].amount - The amount of the shipment charge.
   * @param {string} remarkExtra.charges[].condition - The condition associated with the shipment charge.
   * @param {string} remarkExtra.charges[].shipmentId - The ID of the shipment.
   * @returns {JSX.Element} - A React component that renders the shipment charges breakdown.
   */
  const renderShipmentBreakdown = (remarkExtra) => {
    return (
      <View style={{ marginHorizontal: '1em', marginVertical: '1em' }}>
        <List.Accordion title="Shipment Charges Breakdown">
          <Table
            items={remarkExtra?.charges}
            displayKeys={['name', 'amount', 'shipmentId']}
            titleByKey={{
              name: 'Name',
              amount: 'Amount',
              shipmentId: 'Shipment Id',
            }}
            formatterByKey={{
              amount: (_, item) => {
                return <Text>$ {item.amount}</Text>;
              },
            }}
          />
        </List.Accordion>
      </View>
    );
  };

  return (
    <Card style={[styles.card, isMobile ? styles.mobileCard : styles.desktopCard]}>
      <Card.Title
        title="Transaction Details"
        left={(props) => <Avatar.Icon {...props} icon={'currency-usd'} />}
      />
      <Divider />
      {transaction?.amount ? (
        <View style={styles.contentWrapper}>
          <Text style={styles.amountNumber}>$ {transaction?.amount}</Text>
          <Card.Content
            style={[
              styles.transactionStatus,
              { backgroundColor: STATUS_COLORS[transaction?.status] || STATUS_COLORS.Default },
            ]}>
            <Text>Status: {transaction?.status}</Text>
          </Card.Content>
          {transaction && (
            <View style={styles.transactionsInfoWrapper}>
              <Card.Title title="Transaction ID" subtitle={transaction?.id} />
              <Divider />
              <Card.Title title="Transaction Information" subtitle={transaction?.remark} />
            </View>
          )}
        </View>
      ) : (
        <View style={styles.contentWrapper}>
          <Text style={[styles.amountNumber, { fontSize: 18 }]}>No transactions found</Text>
        </View>
      )}
      {shouldRenderWarehouseCharges && renderWarehouseCharges(transaction?.remarkExtra)}
      {shouldRenderShippingCharges &&
        renderShippingCharges(transaction?.remarkExtra?.shippingCharge)}
      {shouldRenderShipmentBreakdown && renderShipmentBreakdown(transaction?.remarkExtra)}
    </Card>
  );
};

const styles = StyleSheet.create({
  card: {
    marginHorizontal: '1em',
    marginTop: '1em',
    marginBottom: '5em',
  },
  desktopCard: {
    marginHorizontal: '0.4em',
    minWidth: '300px',
  },
  mobileCard: {
    marginHorizontal: '1em',
    marginBottom: '1em',
  },

  contentWrapper: {
    marginHorizontal: '1em',
    marginVertical: '1em',
    borderRadius: 26,
    borderColor: '#F0F0F0',
    borderWidth: 2,
  },

  amountNumber: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: '0.5em',
    color: '#565656',
  },

  transactionStatus: {
    textAlign: 'center',
    alignItems: 'center',
    height: '3em',
    justifyContent: 'center',
  },

  transactionsInfoWrapper: {
    paddingVertical: '1em',
  },

  contentHeader: {
    fontWeight: 'bold',
    marginBottom: '1em',
  },

  listItemTitle: {
    fontStyle: 'italic',
  },

  listItemText: {
    alignSelf: 'center',
    marginRight: '2em',
  },
});

export default memo(TransactionDetail);
