import { Formik } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { countryStateCityCurrencyStore } from '../../store';
import { Button, ButtonGroup, Divider, Icon, Layout, Text } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import Autocomplete from '../Autocomplete';
import { TextInputField } from '../input/TextInputField';
import * as Yup from 'yup';

export const AddressForm = observer(({ onSubmit = () => {}, onCancel = () => {}, value }) => {
  const COUNTRIES = countryStateCityCurrencyStore.countryCodes;

  const [data, setData] = useState({});

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      validationSchema={Yup.object({
        country: Yup.string().required(),
        state: Yup.string().required(),
        city: Yup.string().required(),
        post_code: Yup.string().required(),
        name: Yup.string().required(),
        address: Yup.string().required(),
      })}
      onSubmit={(value, formikActions) => {
        onSubmit(value);
        formikActions.setSubmitting(false);
      }}>
      {(props) => {
        const [states, setStates] = useState([]);
        useEffect(() => {
          (async () => {
            if (props.values.country) {
              const states = await countryStateCityCurrencyStore.getStateCodesOfCountry(
                props.values.country,
              );
              setStates(states);
            }
          })();
        });

        const [cities, setCities] = useState([]);
        useEffect(() => {
          (async () => {
            if (props.values.country && props.values.state) {
              const cities = await countryStateCityCurrencyStore.getCitiesOfState(
                props.values.country,
                props.values.state,
              );
              setCities(cities);
            }
          })();
        });
        return (
          <Layout style={styles.formContainer}>
            <Text category="h5" style={styles.title}>
              {'Address'}
            </Text>
            <Divider />
            <TextInputField label="Code" name="code" style={styles.field} {...props} />
            <Autocomplete
              label="Country"
              name="country"
              value={props.values.country}
              error={props.errors.country}
              touched={props.touched.country}
              style={styles.field}
              placeholder="Select a country"
              options={COUNTRIES}
              onSelect={props.handleChange('country')}
              onBlur={props.handleBlur('country')}
            />
            <Autocomplete
              label="State"
              name="state"
              value={props.values.state}
              error={props.errors.state}
              touched={props.touched.state}
              style={styles.field}
              placeholder="Select a state"
              disabled={!props.values.country}
              options={states}
              onSelect={props.handleChange('state')}
              onBlur={props.handleBlur('state')}
            />
            <Autocomplete
              label="City"
              name="city"
              value={props.values.city}
              error={props.errors.city}
              touched={props.touched.city}
              placeholder="Select a city"
              style={styles.field}
              disabled={!props.values.state}
              options={cities}
              onSelect={props.handleChange('city')}
              onBlur={props.handleBlur('city')}
            />
            <TextInputField name="name" style={styles.field} label="Name" {...props} />
            <TextInputField
              label="Phone"
              name="phone"
              style={styles.field}
              textContentType="telephoneNumber"
              keyboardType="phone-pad"
              {...props}
            />
            <TextInputField
              label="Email"
              name="email"
              style={styles.field}
              textContentType="emailAddress"
              keyboardType="email-address"
              {...props}
            />
            <TextInputField
              label="Post code"
              name="post_code"
              style={styles.field}
              textContentType="postalCode"
              keyboardType="number-pad"
              {...props}
            />
            <TextInputField label="Company" name="company" style={styles.field} {...props} />
            <TextInputField
              label="House number"
              name="house_number"
              style={styles.field}
              {...props}
            />
            <TextInputField label="Address" name="address" style={styles.field} {...props} />
            <Divider />
            <ButtonGroup appearance="ghost" style={styles.btnContainer}>
              <Button
                appearance="outline"
                status="primary"
                accessoryLeft={(props) => <Icon {...props} name="save-outline" />}
                loading={props.isSubmitting}
                disabled={props.isSubmitting || !props.isValid || !props.dirty}
                onPress={props.handleSubmit}>
                Save
              </Button>
              <Button
                appearance="outline"
                status="danger"
                accessoryLeft={(innerProps) => <Icon {...innerProps} name="close-circle-outline" />}
                loading={props.isSubmitting}
                disabled={props.isSubmitting}
                onPress={onCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </Layout>
        );
      }}
    </Formik>
  );
});

const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  btnContainer: {
    marginHorizontal: '5px',
    justifyContent: 'space-evenly',
    marginVertical: 8,
  },
  formContainer: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 30,
    alignSelf: 'center',
    overflowY: 'auto',
  },
  title: {
    marginVertical: 10,
  },
});
