import { useNavigation } from '@react-navigation/native';
import { Divider, Layout, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Dimensions, StyleSheet } from 'react-native';
import { paperNativeTheme } from 'src/core/theme';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { useIsMobile } from '../../core/responsive.utils';
import {
  countryStateCityCurrencyStore,
  outboundOrderTableStore,
  warehouseAccountStore,
} from '../../store';
import CreateShipmentForm from '../../components/CreateShipmentForm';

const windowHeight = Dimensions.get('window').height;

export default observer(() => {
  const navigation = useNavigation();

  return (
    <Layout style={[styles.formContainer]}>
      <WarehouseAccountSelectionModal
        visible={!warehouseAccountStore.selectedWarehouseAccount}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
        }}
        onCancel={() => navigation.navigate('DashboardScreen')}
      />
      <Text category="h5" style={styles.title}>
        Create Shipment(s)
      </Text>
      <Divider />
      <CreateShipmentForm
        onSuccess={() => navigation.navigate('CourierShipmentList')}
        warehouseAccountId={warehouseAccountStore?.selectedWarehouseAccount?.id}
      />
    </Layout>
  );
});

export const styles = StyleSheet.create({
  field: {
    marginVertical: 8,
  },
  title: {
    marginVertical: 10,
  },
  button: {
    marginVertical: 10,
    marginHorizontal: 2,
  },
  desktopButtonContainer: {
    flexDirection: 'row-reverse ',
  },
  mobileButtonContainer: {
    flexDirection: 'column',
  },
  formContainer: {
    height: windowHeight - 80,
    overflowY: 'auto',
    borderColor: paperNativeTheme.colors.secondary,
    borderWidth: '0.5px',
    borderRadius: '4px',
    padding: '20px',
  },
});
