import { makeAutoObservable } from 'mobx';
import { unixMillisecondToDate } from '../core/utils/utils';
import { PICKING_TASK_STATUS_MAP, PICKING_TASK_TYPE_MAP } from '@ezom/library/lib/cjs/constants';
import { gql } from '@apollo/client';
import { volumeCmToMetre, weightGramToKg } from '@ezom/library/lib/cjs/utils';

const DEFAUL_PARAMS = {
  id: undefined,
  warehouse_code: undefined,
  create_time_start: undefined,
  create_time_end: undefined,
  status: undefined,
  type: undefined,
};

const CANCEL_PICKING_TASK = gql`
  mutation cancelPickingTask($warehouseAccountId: ID!, $id: ID!) {
    cancelPickingTask(warehouseAccountId: $warehouseAccountId, id: $id)
  }
`;

const START_PICKING_TASK = gql`
  mutation startPickingTask($warehouseAccountId: ID!, $id: ID!) {
    startPickingTask(warehouseAccountId: $warehouseAccountId, taskId: $id) {
      id
    }
  }
`;

const FINISH_PICKING = gql`
  mutation finishPicking($warehouseAccountId: ID!, $id: ID!) {
    finishPicking(warehouseAccountId: $warehouseAccountId, taskId: $id) {
      id
    }
  }
`;

const FINISH_PACKING = gql`
  mutation finishPacking($warehouseAccountId: ID!, $id: ID!) {
    finishPacking(warehouseAccountId: $warehouseAccountId, taskId: $id) {
      id
    }
  }
`;

const FINISH_PACKING_OC = gql`
  mutation finishPackingOc(
    $warehouseAccountId: ID!
    $taskId: ID!
    $ocId: ID!
    $packedItems: [PackedItemInput!]
  ) {
    finishPackingOc(
      warehouseAccountId: $warehouseAccountId
      taskId: $taskId
      ocId: $ocId
      packedItems: $packedItems
    ) {
      id
    }
  }
`;

const PICKING_TASKS_QUERY = gql`
  query pickingTasks(
    $warehouseAccountIds: [ID!]!
    $ids: [ID!]
    $warehouse_code: ID
    $create_time_start: Float
    $create_time_end: Float
    $status: String
    $type: String
    $page_no: Int
    $page_size: Int
  ) {
    pickingTasks(
      warehouseAccountIds: $warehouseAccountIds
      ids: $ids
      warehouse_code: $warehouse_code
      create_time_start: $create_time_start
      create_time_end: $create_time_end
      status: $status
      type: $type
      page_no: $page_no
      page_size: $page_size
    ) {
      pickingTasks {
        id
        warehouseAccountId
        status
        type
        total_weight
        total_volume
        sku_types
        qty_to_pick
        qty_picked
        create_user
        create_time
        update_time
        operator
        start_time
        complete_time
        time_spent
        warehouse_code
        dispatch_channel
      }
      total
      page_no
      page_size
    }
  }
`;

const PICKING_TASK = gql`
  query pickingTask($warehouseAccountIds: [ID!]!, $id: ID!) {
    pickingTasks(warehouseAccountIds: $warehouseAccountIds, ids: [$id]) {
      pickingTasks {
        id
        warehouseAccountId
        status
        type
        total_weight
        total_volume
        sku_types
        qty_to_pick
        qty_picked
        create_user
        create_time
        update_time
        operator
        start_time
        complete_time
        time_spent
        warehouse_code
        dispatch_channel
        picking_sku_details {
          sku_id
          sku_code
          sku_name
          consignment_no
          status
          batch_no
          qty_to_pick
          qty_picked
          location_aisle
          location_unit
          location_shelf
          location_section
          operator
          start_time
          complete_time
          time_spent
        }
      }
      total
      page_no
      page_size
    }
  }
`;

const SUBMIT_PICKING_TASK = gql`
  mutation submitPickingTask($warehouseAccountId: ID!, $id: ID!) {
    submitPickingTask(warehouseAccountId: $warehouseAccountId, id: $id) {
      id
    }
  }
`;

const REPORT_OC_EXCEPTION = gql`
  mutation reportOutboundOrderException(
    $warehouseAccountId: ID!
    $consignment_no: ID!
    $exceptionReason: String!
  ) {
    reportOutboundOrderException(
      warehouseAccountId: $warehouseAccountId
      consignment_no: $consignment_no
      exceptionReason: $exceptionReason
    ) {
      consignment_no
    }
  }
`;

export class PickingTaskTableStore {
  searchOpen = false;
  filterParams = DEFAUL_PARAMS;
  ordersToCancel = new Set();
  cancelDialogueOpen = false;
  sortOption = [{ key: 'create_time', direction: 'descending' }];
  searchTerm = '';
  selectedIds = new Set();
  currentPage = 0;
  pageSize = 20;
  total = null;
  loading = true;
  error = null;
  cachedItems = null;
  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};

  DISPLAY_KEYS = [
    'id',
    'status',
    'type',
    'sku_types',
    'qty_to_pick',
    'total_weight',
    'total_volume',
    'create_time',
    'dispatch_channel',
    'warehouse_code',
  ];

  TITLE_BY_KEY = {
    id: 'ID',
    status: 'Status',
    type: 'Type',
    warehouse_code: 'Warehouse',
    sku_types: '#SKU Type',
    qty_to_pick: '#SKU Qty',
    total_weight: 'Weight (kg)',
    total_volume: 'Volume (m³)',
    create_time: 'Create Time',
    dispatch_channel: 'Channel',
  };

  FORMATTER_BY_KEY = {
    create_time: unixMillisecondToDate,
    status: (s) => PICKING_TASK_STATUS_MAP[s],
    type: (s) => PICKING_TASK_TYPE_MAP[s],
    warehouse_code: (w) => this.warehousesStore.getWarehouseNameEn(w),
    total_weight: weightGramToKg(false),
    total_volume: volumeCmToMetre(false),
  };

  constructor(client, warehousesStore, warehouseAccountStore) {
    this.client = client;
    this.warehousesStore = warehousesStore;
    this.warehouseAccountStore = warehouseAccountStore;

    makeAutoObservable(
      this,
      {
        client: false,
        warehousesStore: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  openSearch = () => {
    this.searchOpen = true;
  };

  closeSearch = () => {
    this.searchOpen = false;
  };

  closeCancelDialogue = () => {
    this.cancelDialogueOpen = false;
  };

  openCancelDialogue = () => {
    this.cancelDialogueOpen = true;
  };

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.searchTerm = '';
    this.cachedItems = null;
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.cachedItems = null;
  };

  setSelectedIds = (selectedIds) => {
    this.selectedIds = selectedIds;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
  };

  async fetchItems() {
    try {
      this.loading = true;
      if (this.searchTerm) {
        let pickingTasks = [];
        const { data } = await this.client.query({
          query: PICKING_TASKS_QUERY,
          variables: {
            ...this.filterParams,
            create_time_start: this.filterParams.create_time_start?.getTime(),
            create_time_end: this.filterParams.create_time_end?.getTime(),
            ids: this.searchTerm,
            warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          },
          fetchPolicy: 'no-cache',
        });
        pickingTasks = pickingTasks.concat(data.pickingTasks.pickingTasks);
        this.total = data.pickingTasks.total;
        this.cachedItems = pickingTasks;
      } else {
        const {
          data: { pickingTasks },
        } = await this.client.query({
          query: PICKING_TASKS_QUERY,
          variables: {
            ...this.filterParams,
            ids: this.filterParams?.id ? [this.filterParams.id] : undefined,
            create_time_start: this.filterParams.create_time_start?.getTime(),
            create_time_end: this.filterParams.create_time_end?.getTime(),
            page_size: this.pageSize,
            page_no: this.currentPage + 1,
            warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          },
          fetchPolicy: 'no-cache',
        });
        this.cachedItems = pickingTasks.pickingTasks;
        this.total = pickingTasks.total;
      }
      this.error = null;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  *getPickingTask(id, warehouseAccountIds) {
    try {
      const { data } = yield this.client.query({
        query: PICKING_TASK,
        variables: {
          warehouseAccountIds:
            warehouseAccountIds || this.warehouseAccountStore.warehouseAccountIds,
          id,
        },
        fetchPolicy: 'no-cache',
      });
      return data.pickingTasks?.pickingTasks?.[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  *cancelItem(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: CANCEL_PICKING_TASK,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.cancelPickingTask;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *startPickingTask(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: START_PICKING_TASK,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.startPickingTask;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *finishPicking(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: FINISH_PICKING,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.finishPicking;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *finishPacking(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: FINISH_PACKING,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.finishPacking;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *finishPackingOc(warehouseAccountId, taskId, ocId, packedItems) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: FINISH_PACKING_OC,
        variables: {
          warehouseAccountId,
          taskId,
          ocId,
          packedItems,
        },
      });
      return data.finishPackingOc;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *submitPickingTask(warehouseAccountId, id) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: SUBMIT_PICKING_TASK,
        variables: {
          warehouseAccountId,
          id,
        },
      });
      return data.submitPickingTask;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *reportOutboundOrderException(warehouseAccountId, consignment_no, exceptionReason) {
    this.loading = true;
    try {
      const { data } = yield this.client.mutate({
        mutation: REPORT_OC_EXCEPTION,
        variables: {
          warehouseAccountId,
          consignment_no,
          exceptionReason,
        },
      });
      return data.reportOutboundOrderException;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };
}
