import { Button, Icon, Layout, Popover, Text } from '@ui-kitten/components';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Modal } from './Modal';
import { ezTheme } from '../core/theme';
import { TouchableWithoutFeedback } from 'react-native';

export const ReconnectButton = ({ reconnectHandle, showInfo = false }) => {
  const [visible, setVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);

  const renderButtonReconnect = ({ onPress }) => {
    return (
      <Button
        appearance="outline"
        size="small"
        onPress={onPress}
        accessoryLeft={(props) => <Icon {...props} name="flip-2-outline" />}>
        Re-connect
      </Button>
    );
  };

  return (
    <React.Fragment>
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}>
        <Popover
          anchor={() =>
            renderButtonReconnect({
              onPress: () => {
                setVisible(true);
              },
            })
          }
          visible={infoVisible}
          backdropStyle={{ backgroundColor: ezTheme.backdropModalColor }}
          onBackdropPress={() => setInfoVisible(false)}>
          <Layout
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingVertical: 10,
              maxWidth: 300,
            }}>
            <Text>{`The system will attempt to reconnect on its own, so there's no need to manually press the "Reconnect" button unless you notice a discrepancy. If you realise you've reconnected using the wrong account, you can use the "Reconnect" button to sign in with the correct account.`}</Text>
          </Layout>
        </Popover>
        {showInfo && (
          <TouchableWithoutFeedback
            onPress={() => {
              setInfoVisible(true);
            }}>
            <Icon
              fill="#8F9BB3"
              name="info-outline"
              style={{ width: 23, height: 23, marginLeft: 10 }}
              onPress={() => {
                setInfoVisible(true);
              }}
            />
          </TouchableWithoutFeedback>
        )}
      </View>
      <Modal
        width={500}
        visible={visible}
        onBackdropPress={() => {
          setVisible(false);
        }}>
        <View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Icon name="alert-triangle" fill={'#d69d00'} style={{ width: 32, height: 32 }} />
            <Text style={{ paddingBottom: 10, paddingLeft: 20 }}>
              {`Please make sure you are reconnecting to the same account, otherwise, the system might not work correctly`}
            </Text>
          </View>

          {renderButtonReconnect({
            onPress: async () => {
              await reconnectHandle?.();
              setVisible(false);
            },
          })}
        </View>
      </Modal>
    </React.Fragment>
  );
};
