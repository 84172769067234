import React from 'react';
import { warehouseAccountStore } from '../store';
import { observer } from 'mobx-react';
import { StyleSheet } from 'react-native';
import Select from './Select';
import { Divider } from 'react-native-paper';

// The default value to display in the dropdown when no warehouse account is selected.
const defaultDropDownValue = 'Select a warehouse account';

/**
 * A React component that renders a dropdown selection UI element for selecting a warehouse account.
 *
 * The component uses the `warehouseAccountStore` from the application's state management to populate the dropdown options and manage the selected account.
 *
 * The component renders a button with an icon and a tooltip, as well as a `Select` component from the `@ui-kitten/components` library to display the dropdown.
 *
 * The `Select` component is populated with the list of warehouse accounts from the `warehouseAccountStore`,
 * ... and the selected account is stored in the component's state and the `warehouseAccountStore`.
 *
 * @returns {JSX.Element} - The rendered WarehouseDropdownSelection component.
 */
const WarehouseDropdownSelection = observer(() => {
  return (
    <>
      <Divider style={styles.divider} />
      <Select
        style={styles.container}
        labelStyle={styles.label}
        label="Warehouse account"
        placeholder={defaultDropDownValue}
        options={warehouseAccountStore.warehouseAccounts.map(
          (account) => account.id + '. ' + account.name,
        )}
        value={warehouseAccountStore?.selectedWarehouseAccount?.name || defaultDropDownValue}
        onChange={(index) => {
          const selectedAccount = warehouseAccountStore.warehouseAccounts.find(
            (account) => account.id + '. ' + account.name === index,
          );
          warehouseAccountStore.setSelectedWarehouseAccount(selectedAccount);
        }}
      />
      <Divider style={styles.divider} />
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    minWidth: '5rem',
    backgroundColor: 'transparent',
  },
  divider: {
    width: 2,
    height: '100%',
    backgroundColor: 'white',
    marginHorizontal: '0.5rem',
  },
  label: {
    color: 'white',
  },
});

export default WarehouseDropdownSelection;
