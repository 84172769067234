import React, { memo, useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { Chip, Card } from 'react-native-paper';
import SearchBar from './SearchBar';
import { useIsMobile } from 'src/core/responsive.utils';
import {
  Select,
  SelectItem,
  Input,
  Text,
  Layout,
  Popover,
  Button,
  IndexPath,
  Icon,
} from '@ui-kitten/components';

const SearchBanner = ({
  disableSearch = true,
  requireButtonClickToSearch = false,
  converters = {},
  indicators = [],
  filterOptions = [],
  onSearchTermChange = () => {},
  onFilterChange = () => {},
  onPressAdvancedSearch = () => {},
  onFilterClear = () => {},
}) => {
  const isMobileDevice = useIsMobile();
  const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
  const [visible, setVisible] = React.useState(false);
  const filterField =
    selectedIndex.row < filterOptions.length ? filterOptions[selectedIndex.row].value : null;
  const [filterMin, setFilterMin] = useState(null);
  const [filterMax, setFilterMax] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const _clearFilter = () => {
    setSelectedIndex(new IndexPath(0));
    setFilterMin(null);
    setFilterMax(null);
    setSearchTerm('');
  };
  return (
    <Card visible={true} style={styles.bannerContainer} actions={[]}>
      <Layout style={isMobileDevice ? styles.mobileBanner : styles.desktopBanner}>
        <SearchBar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          requireButtonClickToSearch={requireButtonClickToSearch}
          disableSearch={disableSearch}
          onSearchTermChange={onSearchTermChange}
          inputPlaceholder="Search inventory"
          inputSize="medium">
          {' '}
        </SearchBar>

        <Layout style={isMobileDevice ? styles.mobileButtonGroup : styles.desktopButtonGroup}>
          <Popover
            visible={visible}
            anchor={() => (
              <Button
                status="primary"
                appearance="ghost"
                onPress={() => setVisible(true)}
                accessoryRight={visible ? ArrowUpIcon : ArrowDownIcon}
                style={styles.button}>
                Filter
              </Button>
            )}
            onBackdropPress={() => {
              _clearFilter();
              setVisible(false);
            }}>
            <Layout style={styles.popover}>
              <Text> Show all inventory where </Text>
              <Select
                onSelect={(index) => {
                  if (index.row >= 0 && index.row < filterOptions.length) {
                    setSelectedIndex(index);
                    const field = filterOptions[index.row].value;
                  }
                }}
                selectedIndex={selectedIndex}
                value={filterOptions.find(({ value }) => value === filterField)?.label}>
                {filterOptions.map(({ value, label }) => (
                  <SelectItem key={value} title={label} />
                ))}
              </Select>
              {selectedIndex.row >= 0 && (
                <>
                  <Layout style={styles.rowContainer}>
                    <Text>from</Text>
                    <Input
                      defaultValue={filterMin}
                      placeholder="min"
                      style={styles.rangeInput}
                      onChangeText={(text) => setFilterMin(Number(text))}
                    />
                    <Text>to</Text>
                    <Input
                      defaultValue={filterMax}
                      placeholder="max"
                      style={styles.rangeInput}
                      onChangeText={(text) => setFilterMax(Number(text))}
                    />
                  </Layout>
                  <Button
                    onPress={() => {
                      filterField &&
                        onFilterChange({
                          [filterField]: {
                            min: filterMin,
                            max: filterMax,
                          },
                        });
                    }}>
                    Apply
                  </Button>
                </>
              )}
            </Layout>
          </Popover>

          <Button
            status="primary"
            appearance="ghost"
            onPress={onPressAdvancedSearch}
            accessoryRight={ArrowUpRightIcon}
            style={styles.button}>
            Advanced
          </Button>
          <Button
            status="danger"
            appearance="ghost"
            onPress={() => {
              onFilterClear();
              _clearFilter();
            }}
            style={{ ...styles.button, width: '115px' }}>
            Reset filter
          </Button>
        </Layout>
      </Layout>
      <Layout style={styles.indicator}>
        {indicators.map((indicator) => (
          <Chip style={styles.chip} key={indicator}>
            {indicator}
          </Chip>
        ))}
      </Layout>
    </Card>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    marginBottom: '0.5em',
    padding: '1em',
  },
  desktopBanner: {
    maxWidth: '1800px',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    justifyContent: 'center',
    width: '100%',
  },
  mobileBanner: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '5px',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: '0.4em',
    marginHorizontal: '0.2em',
    opacity: '0.5',
  },
  popover: {
    margin: '0.5em',
    padding: '0.5em',
  },
  rangeInput: {
    width: '100px',
    margin: '0.5em',
  },
  rowContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  desktopButtonGroup: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  mobileButtonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: '1',
    gap: '5px',
  },
  mobileSearchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    width: '100%',
  },
  button: {
    height: '45px',
  },
  icon: {
    width: '28px',
    height: '28px',
    stroke: 'white',
    strokeWidth: '0.8',
  },
});

const ArrowDownIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-downward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpIcon = (props) => (
  <Icon
    {...props}
    name="arrow-ios-upward-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

const ArrowUpRightIcon = (props) => (
  <Icon
    {...props}
    name="diagonal-arrow-right-up-outline"
    style={[props.style, styles.icon, { width: '20px', height: '20px' }]}
  />
);

export default memo(SearchBanner);
