import React, { useMemo } from 'react';
import { Linking, StyleSheet } from 'react-native';
import { Banner, Portal, Modal, Provider as PaperProvider } from 'react-native-paper';
import { observer } from 'mobx-react';
import Table from '../../components/Table';
import Background from '../../components/Background';
import { useIsMobile } from '../../core/responsive.utils';
import CsvUploader from '../../components/CsvUploader';
import { Button, Card, Icon, List, ListItem, Text } from '@ui-kitten/components';
import Dialogue from '../../components/Dialogue';
import { useQuery } from '@apollo/client';
import { omit, pick } from 'ramda';
import SkuMappingTableControl from '../../components/SkuMappingTableControl';
import { UIStatusWrapper } from '../../components/ui-status';
import { GET_STORES, unixMillisecondToDate } from '../../core/utils/utils';
import AddSkuMappingsForm from '../../components/AddSkuMappingsForm';
import { useIsFocused } from '@react-navigation/core';
import { skuMappingStore, skuMappingTableStore, warehouseAccountStore } from '../../store/index';
import { paperNativeTheme, uiKittenTheme } from '../../core/theme';
import { SkuMappingWarningModal } from '../../components/SkuMappingWarningModal';
import { WarehouseAccountSelectionModal } from '../../components/WarehouseAccountSelectionModal';
import { map } from 'ramda';

const getId = (item) => item.id;
const selectedSkuMappings = (list, selectedIds) => list.filter((i) => selectedIds.has(getId(i)));

export default observer(() => {
  const MAX_NO_OF_ITEMS_AUTO_SEARCH = 200;
  const { data: storesData, error: storesError, loading: storesLoading } = useQuery(GET_STORES);
  const stores = useMemo(() => (storesData && storesData.stores) || [], [storesData]);
  const enabledStoreIds = useMemo(
    () => stores?.filter((s) => !s.isDisabled).map((s) => s.id),
    [stores],
  );

  const isMobile = useIsMobile();

  const addSkuMappings = async (newSkuMappings, warehouseAccountId) => {
    await skuMappingStore.addItems(
      newSkuMappings.map((s) => ({
        ...s,
        qty: Number.parseInt(s.qty),
      })),
      warehouseAccountId,
    );
    await skuMappingStore.fetchItems();
  };

  const isFocused = useIsFocused();
  // Refetch data when screen is focused
  React.useEffect(() => {
    if (isFocused) {
      skuMappingTableStore.dataStore.fetchItems();
    }
  }, [isFocused]);

  return (
    <Background fullWidth={true}>
      <Banner
        style={{ backgroundColor: uiKittenTheme['color-primary-100'] }}
        visible={skuMappingTableStore.bannerVisible}
        actions={
          skuMappingTableStore.unmappedSkuModalOpen
            ? []
            : [
                {
                  label: 'Learn more',
                  onPress: skuMappingTableStore.openUnmappedSkuModal,
                },
              ]
        }
        icon={() => (
          <Icon
            fill={paperNativeTheme.colors.danger}
            name="alert-triangle-outline"
            status="danger"
            style={{ width: 20, height: 20 }}
          />
        )}>
        There are unmapped SKUs, please fix them otherwise there might be errors in forecast and
        fulfillment.
      </Banner>
      <SkuMappingWarningModal
        visible={skuMappingTableStore.unmappedSkuModalOpen}
        skus={skuMappingTableStore.dataStore.unmappedSkus}
        onBackdropPress={skuMappingTableStore.closeUnmappedSkuModal}
      />
      <SkuMappingTableControl
        onSearchTermChange={skuMappingTableStore.setSearchTerm}
        requireButtonClickToSearch={
          skuMappingTableStore.matchingItems.length > MAX_NO_OF_ITEMS_AUTO_SEARCH
        }
        disableSearch={skuMappingTableStore.dataStore.loading || storesLoading}
      />
      <Card>
        <UIStatusWrapper
          status={{
            error: skuMappingTableStore.dataStore.error || storesError,
            indeterminate: skuMappingTableStore.dataStore.loading || storesLoading,
            empty: !skuMappingTableStore.dataStore.items,
          }}>
          <Table
            tableStyle={{ minHeight: '60vh' }}
            getItems={() => skuMappingTableStore.matchingItems}
            getId={getId}
            displayKeys={skuMappingTableStore.DISPLAY_KEYS}
            titleByKey={{
              storeSku: 'Store SKU',
              storeId: 'Store',
              storeProductId: 'Product ID',
              warehouseSku: 'Warehouse SKU',
              warehouseCode: 'Warehouse Code',
              createdTime: 'Created At',
              qty: 'QTY',
            }}
            formatterByKey={{
              ...skuMappingStore.FORMATTER_BY_KEY,
              storeId: (storeId) => {
                return stores?.find((s) => s.id === storeId)?.name;
              },
              createdTime: unixMillisecondToDate,
            }}
            sort="multi"
            isFabVisible={isFocused}
            fabActions={(selectedIds) => {
              const actions = [];

              if (!isMobile && selectedIds.size === 0) {
                actions.push({
                  icon: 'file-upload-outline',
                  label: 'Upload SKU Mappings in batch',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      skuMappingTableStore.openCsvModal();
                    } else {
                      skuMappingTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        skuMappingTableStore.openCsvModal();
                      });
                      skuMappingTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
              }
              if (selectedIds.size === 0) {
                actions.push({
                  icon: 'download-outline',
                  label: 'Export all to CSV',
                  onPress: () => {
                    // extract ALL attributes from skuMappingTableStore.skuMappings
                    // these csvHeaders can be extended to have nested attributes
                    // The nested structures must be pre-defined.
                    const csvHeaders =
                      skuMappingTableStore.matchingItems.length > 0
                        ? Object.keys(
                            omit(
                              ['__typename', 'createdTime', 'id'],
                              skuMappingTableStore.matchingItems[0],
                            ),
                          )
                        : [];
                    skuMappingTableStore.exportCsv(skuMappingTableStore.matchingItems, csvHeaders);
                  },
                });

                actions.push({
                  icon: 'plus',
                  label: 'Add SKU Mapping',
                  onPress: () => {
                    if (warehouseAccountStore.selectedWarehouseAccount) {
                      skuMappingTableStore.openSkuMappingForm();
                    } else {
                      skuMappingTableStore.setOnWarehouseSelectionSubmit((warehouseAccount) => {
                        warehouseAccountStore.setSelectedWarehouseAccount(warehouseAccount);
                        skuMappingTableStore.openSkuMappingForm();
                      });
                      skuMappingTableStore.setWarehouseSelectionVisible(true);
                    }
                  },
                });
              }

              if (selectedIds.size > 0) {
                actions.push({
                  icon: 'export',
                  label: `Export selected ${selectedIds.size > 1 ? 'SKUs' : 'SKU'} to CSV`,
                  onPress: () => {
                    const skuMappings = selectedSkuMappings(
                      skuMappingTableStore.matchingItems,
                      selectedIds,
                    );
                    const csvHeaders =
                      skuMappings.length > 0
                        ? Object.keys(omit(['__typename', 'createdTime', 'id'], skuMappings[0]))
                        : [];
                    skuMappingTableStore.exportCsv(skuMappings, csvHeaders);
                  },
                });

                actions.push({
                  icon: 'trash-can-outline',
                  label: `Delete selected ${selectedIds.size > 1 ? 'SKU Mappings' : 'SKU Mapping'}`,
                  onPress: () => {
                    skuMappingTableStore.setItemIdsToDelete(selectedIds);
                    skuMappingTableStore.openDeleteSkuMappingsModal();
                  },
                });
              }
              return actions;
            }}
          />
        </UIStatusWrapper>
      </Card>

      <AddSkuMappingsModal
        warehouseAccount={warehouseAccountStore.selectedWarehouseAccount}
        visible={skuMappingTableStore.skuMappingFormOpen}
        stores={stores}
        enabledStoreIds={enabledStoreIds}
        onDismiss={skuMappingTableStore.closeSkuMappingForm}
        onSubmit={async (newSkuMappings, warehouseAccountId) => {
          await addSkuMappings(newSkuMappings, warehouseAccountId);
          skuMappingTableStore.closeSkuMappingForm();
        }}
      />

      <WarehouseAccountSelectionModal
        visible={skuMappingTableStore.warehouseSelectionVisible}
        warehouseAccounts={warehouseAccountStore.warehouseAccounts}
        loading={warehouseAccountStore.loading}
        error={warehouseAccountStore.error}
        onSubmit={(warehouseAccount) => {
          skuMappingTableStore.onWarehouseSelectionSubmit(warehouseAccount);
          skuMappingTableStore.setWarehouseSelectionVisible(false);
        }}
        onCancel={() => skuMappingTableStore.setWarehouseSelectionVisible(false)}
      />

      <CsvUploadModal
        visible={skuMappingTableStore.csvModalOpen}
        onDismiss={skuMappingTableStore.closeCsvModal}
        onSubmit={async (newSkuMappings) => {
          skuMappingTableStore.closeCsvModal();
          await addSkuMappings(
            newSkuMappings.map((skuMapping) =>
              pick(
                [
                  'storeSku',
                  'warehouseSku',
                  'warehouseCode',
                  'warehouseCode',
                  'storeId',
                  'qty',
                  'storeProductId',
                  'storeProductName',
                ],
                map((val) => val && val.toString(), skuMapping),
              ),
            ),
            warehouseAccountStore.selectedWarehouseAccount.id,
          );
        }}
      />
      <DeleteSkuMappingModal
        visible={skuMappingTableStore.deleteSkuMappingsModalOpen}
        onDismiss={skuMappingTableStore.closeDeleteSkusModal}
        onSubmit={async () => {
          await skuMappingTableStore.dataStore.deleteItems(skuMappingTableStore.idsToDelete);
          skuMappingTableStore.closeDeleteSkusModal();
        }}>
        <Text>Are you sure you want to delete the SKU mapping(s)?</Text>
        <List
          data={selectedSkuMappings(
            skuMappingTableStore.matchingItems,
            skuMappingTableStore.idsToDelete,
          )}
          renderItem={({ item }) => (
            <ListItem title={item.storeSku} description={item.warehouseSku} />
          )}
        />
      </DeleteSkuMappingModal>
    </Background>
  );
});

const AddSkuMappingsModal = ({
  visible,
  onDismiss,
  onSubmit,
  stores,
  enabledStoreIds,
  warehouseAccount,
}) => (
  <Portal>
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalStyle}>
      <AddSkuMappingsForm
        warehouseAccount={warehouseAccount}
        onDismiss={onDismiss}
        onSubmit={onSubmit}
        stores={stores}
        enabledStoreIds={enabledStoreIds}
      />
    </Modal>
  </Portal>
);

const CsvUploadModal = ({ visible, onDismiss, onSubmit }) => (
  <Portal>
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalStyle}>
      <CsvUploader onDismiss={onDismiss} onSubmit={onSubmit} />
      <Button
        appearance="ghost"
        style={styles.button}
        onPress={() =>
          Linking.openURL(require('src/spreadsheetTemplates/SKUMappingsTemplate.csv'))
        }>
        Download Template
      </Button>
    </Modal>
  </Portal>
);

const DeleteSkuMappingModal = ({ visible, onDismiss, onSubmit, children }) => (
  <Portal>
    <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalStyle}>
      <Dialogue onSubmit={onSubmit} onDismiss={onDismiss}>
        {children}
      </Dialogue>
    </Modal>
  </Portal>
);

const styles = StyleSheet.create({
  modalStyle: {
    minWidth: 400,
    backgroundColor: 'white',
    padding: 20,
    alignSelf: 'center',
    overflowY: 'auto',
    maxHeight: '70%',
  },
});
