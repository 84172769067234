import { flow, makeAutoObservable } from 'mobx';
import { gql } from '@apollo/client';
import { remove, debounce } from 'lodash';

const DELETE_SKU = gql`
  mutation deleteSkus($warehouseAccountIds: [ID!], $skuIds: [ID!]) {
    deleteSkus(warehouseAccountIds: $warehouseAccountIds, skuIds: $skuIds)
  }
`;

const INVENTORY_PAGE = gql`
  query inventoryPage(
    $warehouseAccountIds: [ID!]!
    $searchTerm: String
    $skuCodes: [String!]
    $productName: String
    $minWeight: Float
    $maxWeight: Float
    $minSaleableStock: Float
    $maxSaleableStock: Float
    $minOnwayStock: Float
    $maxOnwayStock: Float
    $minLength: Float
    $maxLength: Float
    $minWidth: Float
    $maxWidth: Float
    $minHeight: Float
    $maxHeight: Float
    $warehouses: [String!]
    $page_no: Int
    $page_size: Int
  ) {
    inventories(
      warehouses: $warehouses
      warehouseAccountIds: $warehouseAccountIds
      searchTerm: $searchTerm
      skuCodes: $skuCodes
      productName: $productName
      minWeight: $minWeight
      maxWeight: $maxWeight
      minLength: $minLength
      maxLength: $maxLength
      minWidth: $minWidth
      maxWidth: $maxWidth
      minHeight: $minHeight
      maxHeight: $maxHeight
      minSaleableStock: $minSaleableStock
      maxSaleableStock: $maxSaleableStock
      minOnwayStock: $minOnwayStock
      maxOnwayStock: $maxOnwayStock
      page_no: $page_no
      page_size: $page_size
    ) {
      inventories {
        warehouseAccountId
        sku_id
        sku_name
        sku_code
        warehouse_code
        saleable_stock
        pending_stock
        available_stock
        onway_stock
        width
        length
        height
        weight
      }
      total
    }
  }
`;

const DEBOUNCE_DELAY_MS = 500;

export class SkuTableStore {
  skuList = [];
  searchOpen = false;
  skuFormOpen = false;
  searchParams = {};
  csvModalOpen = false;
  isBatchUpdate = false;
  deleteSkusModalOpen = false;
  skusToDelete = new Set();
  searchTerm = '';
  syncModalVisisble = false;
  warehouseSelectionVisible = false;
  onWarehouseSelectionSubmit = () => {};
  currentPage = 0;
  pageSize = 20;
  cachedItems = null;
  exportedItems = null; // Store items for exporting csv
  total = null;
  loading = true;
  error = null;

  DISPLAY_KEYS = [
    'sku_code',
    'sku_name',
    'saleable_stock',
    'pending_stock',
    'available_stock',
    'onway_stock',
    'dimension',
    'weight',
    'warehouse_code',
  ];

  SHORTCUT_OPTIONS = [
    {
      value: 'saleable_stock',
      label: 'Saleable stock',
    },
    {
      value: 'weight',
      label: 'Weight (kg)',
    },
  ];

  ADVANCED_OPTIONS = [
    ...this.SHORTCUT_OPTIONS,
    ...[
      {
        value: 'onway_stock',
        label: 'Incoming stock',
      },
      {
        value: 'length',
        label: 'Length (cm)',
      },
      {
        value: 'width',
        label: 'Width (cm)',
      },
      {
        value: 'height',
        label: 'Height (cm)',
      },
    ],
  ];

  INVERTERS = {
    weight: (w) => w && w * 1000,
  };

  TITLE_BY_KEY = {
    sku_name: 'Product Name',
    sku_code: 'Code',
    saleable_stock: 'Saleable Stock',
    available_stock: 'Available Stock',
    onway_stock: 'Incoming Stock',
    dimension: 'Dimension (cm)',
    weight: 'Weight (kg)',
    warehouse_code: 'Warehouse',
    pending_stock: 'Pending stock',
    onway_stock: 'On-way stock',
  };

  constructor(client, dataStore, warehouseAccountStore) {
    this.client = client;
    this.dataStore = dataStore;
    this.warehouseAccountStore = warehouseAccountStore;
    makeAutoObservable(
      this,
      {
        client: false,
        dataStore: false,
        warehouseAccountStore: false,
        deleteItems: flow,
        DISPLAY_KEYS: false,
        INVERTERS: false,
        ADVANCED_OPTIONS: false,
        SHORTCUT_OPTIONS: false,
      },
      { autoBind: true },
    );
  }

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  get filterIndicators() {
    const indicators = [];
    const { width, height, length, weight, saleable_stock, pending_stock } = this.searchParams;
    if (saleable_stock?.min && saleable_stock?.max) {
      indicators.push(`SaleableStock from ${saleable_stock?.min} to ${saleable_stock?.max}`);
    } else if (saleable_stock?.min) {
      indicators.push(`SaleableStock from ${saleable_stock?.min}`);
    } else if (saleable_stock?.max) {
      indicators.push(`SaleableStock from 0 to ${saleable_stock?.max}`);
    }

    if (pending_stock?.min && pending_stock?.max) {
      indicators.push(`PendingStock from ${pending_stock?.min} to ${pending_stock?.max}`);
    } else if (pending_stock?.min) {
      indicators.push(`PendingStock from ${pending_stock?.min}`);
    } else if (pending_stock?.max) {
      indicators.push(`PendingStock from 0 to ${pending_stock?.max}`);
    }

    if (width?.min && width?.max) {
      indicators.push(`Width from ${width?.min} to ${width?.max}`);
    } else if (width?.min) {
      indicators.push(`Width from ${width?.min}`);
    } else if (width?.max) {
      indicators.push(`Width from 0 to ${width?.max}`);
    }

    if (weight?.min && weight?.max) {
      indicators.push(`Weight from ${weight?.min} to ${weight?.max}`);
    } else if (weight?.min) {
      indicators.push(`Weight from ${weight?.min}`);
    } else if (weight?.max) {
      indicators.push(`Weight from 0 to ${weight?.max}`);
    }

    if (height?.min && height?.max) {
      indicators.push(`Height from ${height?.min} to ${height?.max}`);
    } else if (height?.min) {
      indicators.push(`Height from ${height?.min}`);
    } else if (height?.max) {
      indicators.push(`Height from 0 to ${height?.max}`);
    }

    if (length?.min && length?.max) {
      indicators.push(`Length from ${length?.min} to ${length?.max}`);
    } else if (length?.min) {
      indicators.push(`Height from ${length?.max}`);
    } else if (length?.max) {
      indicators.push(`Height from 0 to ${length?.max}`);
    }
    return indicators;
  }

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.clearCachedItems();
  };

  // this function is debounced to prevent multiple calls to the server
  clearCachedItems = debounce(() => {
    this.cachedItems = null;
    this.setCurrentPage(0);
  }, DEBOUNCE_DELAY_MS);

  openSearch = () => {
    this.searchOpen = true;
  };

  closeSearch = () => {
    this.searchOpen = false;
  };

  openSkuForm = () => {
    this.skuFormOpen = true;
  };

  closeSkuForm = () => {
    this.skuFormOpen = false;
  };

  openCsvModal = () => {
    this.csvModalOpen = true;
  };

  setIsBatchUpdate = (isBatchUpdate) => {
    this.isBatchUpdate = isBatchUpdate;
  };

  closeCsvModal = () => {
    this.csvModalOpen = false;
  };

  setSearchText = (text) => {
    this.searchText = text;
  };

  clearSearchParams = () => {
    this.searchTerm = '';
    this.searchParams = {};
    this.clearCachedItems();
  };

  setSearchParams = (params) => {
    this.searchParams = { ...this.searchParams, ...params };
    this.clearCachedItems();
  };

  openDeleteSkusModal = () => {
    this.deleteSkusModalOpen = true;
  };

  closeDeleteSkusModal = () => {
    this.deleteSkusModalOpen = false;
  };

  setSyncModalVisible = (visible) => {
    this.syncModalVisisble = visible;
  };

  setWarehouseSelectionVisible = (visible) => {
    this.warehouseSelectionVisible = visible;
  };

  setOnWarehouseSelectionSubmit = (onSubmit) => {
    this.onWarehouseSelectionSubmit = onSubmit;
  };

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
  };

  async syncSkus() {
    const warehouseAccounts = await this.warehouseAccountStore.getWarehouseAccounts();
    await Promise.all(warehouseAccounts.map((w) => this.dataStore.syncSkus(w.id)));
    this.clearCachedItems();
  }

  *deleteItems(warehouseAccountIds, skuIds) {
    this.loading = true;
    try {
      yield this.client.mutate({
        mutation: DELETE_SKU,
        variables: {
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
          skuIds,
        },
      });
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  }

  async fetchItems(forExport = false) {
    this.loading = true;
    this.error = null;
    try {
      if (this.warehouseAccountStore.warehouseAccountIds.length > 0) {
        let variables = {
          ...this.searchParams,
          searchTerm: this.searchTerm,
          minSaleableStock: getNumber(this.searchParams.saleable_stock?.min),
          maxSaleableStock: getNumber(this.searchParams.saleable_stock?.max),
          minOnwayStock: getNumber(this.searchParams.onway_stock?.min),
          maxOnwayStock: getNumber(this.searchParams.onway_stock?.max),
          minWeight: this.INVERTERS.weight(this.searchParams.weight?.min),
          maxWeight: this.INVERTERS.weight(this.searchParams.weight?.max),
          minHeight: getNumber(this.searchParams.height?.min),
          maxHeight: getNumber(this.searchParams.height?.max),
          minWidth: getNumber(this.searchParams.width?.min),
          maxWidth: getNumber(this.searchParams.width?.max),
          minLength: getNumber(this.searchParams.length?.min),
          maxLength: getNumber(this.searchParams.length?.max),
          skuCodes: this.searchParams.skus,
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        };

        if (!forExport) {
          variables.page_size = this.pageSize;
          variables.page_no = this.currentPage + 1;
        }

        const {
          data: { inventories },
        } = await this.client.query({
          query: INVENTORY_PAGE,
          variables,
          fetchPolicy: 'no-cache',
        });

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = inventories.inventories;
        else this.cachedItems = inventories.inventories;

        this.total = inventories.total;
      }
      this.loading = false;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  get filteredItems() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }
}

const getNumber = (n) => n && Number(n);
